.resources-dashboard-container {
    & {  width: 100%; background: #FFFFFF; display: flex; }
    .resources-right-container { 
      & { border-radius: 20px 0px 0px ; border-top-left-radius: 1rem; background-color: #F5F5F5; width: 100%; height: calc(100vh - 90px); overflow-y: scroll; display: flex; flex-direction: column; gap: 16px; }
      h1 { font-family: $helvetica; font-size: 18px;  font-weight: 300; line-height: 31px; text-align: left; color: #595959; margin: 20px auto; max-width: 92%; }
       .card-container {
            & { background: #FFFFFF; width: 90%; margin: 0px auto; min-height: auto; padding: 20px 20px 40px; border-radius: 14px; }
            h2 { font-family: $helvetica; font-size: 30px;  font-weight: 700; line-height: 36px;  text-align: center; color: #84161D; }
            p { color: #121212; font-family: $helvetica; font-size: 18px; font-weight: 300; line-height: 31px; text-align: center; }
            .cards-sections  {
                & { display: grid;   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); grid-gap: 20px; justify-items: center; }
                .card {
                    & {  background: #EFEFEF; min-height: 330px; width: 306px; border-radius: 5px; display: flex; flex-direction: column; align-items: center; padding: 40px 20px; position: relative; max-width: 90%; }
                    .img-container { background: #44546A; height: 84px; width: 84px; border-radius: 50%; display: flex; justify-content: center; align-items: center; }
                    h3 { font-family: $helvetica; font-size: 21px;  font-weight: 700;  line-height: 30px;  text-align: center;  color: #343333; margin: 5px;  }
                    p { font-family: $helvetica; font-size: 18px; font-weight: 300; line-height: 26px; text-align: center; margin:10px 5px;  max-width: 26ch; }
                    button { background: #84161D; font-family: $helvetica; font-size: 21px; font-weight: 700; line-height: 30.87px; letter-spacing: 1px; text-align: center; border-radius: 25px; padding: 10px 20px; border: none; min-width: 190px; color: #FFFFFF; position: absolute; bottom: 8%; cursor: pointer; }
                }
            }
        }
    }
  
  }



