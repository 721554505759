@import './Assets/Styles/Fonts.scss';
@import './Assets/Styles/Variables.scss';
@import './Components/Layout/Header/Header.scss';
@import './Components/Layout/Footer/Footer.scss';
@import './Pages/Start/Start.scss';
@import './Pages/Intro/Intro.scss';
@import './Pages/Invite/Invite.scss';
@import './Pages/Details/Details.scss';
@import './Pages/PreAssessment/PreAssessment.scss';
@import './Pages/Assessment/Assessment.scss';
@import './Pages/AssessmentAdditional/AssessmentAdditional.scss';
@import './Components/Promo/Promo.scss';
@import './Assets/Styles/Common.scss';
@import './Pages/FirstForm/FirstForm.scss';
@import './Components/ProgressBarSingle/ProgressBarSingle.scss';
@import './Pages/ProgressMilestone/ProgressMilestone.scss';
@import './Pages/Results/ResultsSteward.scss';
@import './Pages/Login/Login.scss';
@import './Pages/Dashboard/Overview/DashboardOverview.scss';
@import './Components/Sidebar/Sidebar.scss';
@import './Components/Scores/Scores.scss';
@import './Components/Discover/Discover.scss';
@import './Components/Matrix/Matrix.scss';
@import './Pages/Dashboard/Downloads/DashboardDownloads.scss';
@import './Pages/Dashboard/Resources/DashboardResources.scss';
@import './Pages/Dashboard/Compare/DashboardCompare.scss';
@import './Pages/Dashboard/Insights/DashboardInsights.scss';
@import './Components/ParishionerType/ParishionerType.scss';




body { background: #44546A; font-family: $primaryfont; color: $textcolor1; margin: auto; overflow-x: hidden; -webkit-font-smoothing: antialiased; }

.desktop { display: block; }
.mobile { display: none; }

.loading { width: 100vw; height: 100vh; background-color: #44546A; display: flex; justify-content: center; align-items: center; }
.css-1jaiqc6-MuiLinearProgress-root.MuiLinearProgress-colorPrimary { background-color: #a7caed !important;}
.css-1jaiqc6-MuiLinearProgress-root .MuiLinearProgress-bar { background-color: #1B608A!important;}

.details.loading { background-color: transparent; }



main {
  & { display: flex;justify-content: center; align-items: center; }
  .logo-left-container {
    & { position: absolute; height: 252px; width: 92px; left: 0px; top: calc(47vh - 200px); /* z-index: 1; */ }
    .logo-left { & {}
      img { height: 252px; width: 92px; }
    }
    img { height: 252px; width: 92px; }
  }
  .assessment-progress-sections-container { & { margin: 40px auto 40px; text-align: center; position: relative; color: #FFFFFF; max-width: 1100px; }
    .main-sections-dots { & { position: relative; max-width: 1100px; min-width: 550px; height: 3px; background-color: #429653; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; left: 0; }
        .main-section-dot { position: relative; bottom: 5px; width: 10px; height: 10px; background-color: #FFFFFF; border: 2px solid black; border-radius: 50%; font-size: 20px; line-height: 20px; }
        .main-section-dot.finished { box-sizing: border-box; width: 40px; height: 40px; bottom: 19px; background-color: #429653; display: flex; justify-content: center; align-items: center; }
        .main-section-dot.active { box-sizing: border-box; height: 40px; width: 40px; bottom: 19px; background-color: #FFFFFF; background-image: radial-gradient(white 40%, #FDBF04 40%); background-image: radial-gradient(#FDBF04 40%, #FDBF04 40%); background-color: #FDBF04; display: flex; justify-content: center; align-items: center;}
        .main-section-dot.unfinished { box-sizing: border-box; height: 40px; width: 40px; background-color: #8F7070; bottom: 19px; border: 2px solid black; display: flex; justify-content: center; align-items: center;}
      }
      .main-section-left { left: 75px;}
    }
    .Leadership{margin-right:0;}
    .Evangelization{margin:0 auto;}
    .main-section-name { position: relative; text-align: center; text-transform: uppercase;left:-32%; }
    .main-section-name.finished { color: #FFFFFF; font-weight: 400; }
    .main-section-name.active { color: #FDBF04; font-weight: 700; }
    .main-section-name.unfinished { color: #FFFFFF; font-weight: 400; }
    .main-sections-names { & { position: relative; max-width: 1100px; height: 3px; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; width: 10ch; }
      }
    }
  }

  .container { 
    & { margin: 18px auto; max-width: 1140px; border-radius: 10px; z-index: 1; } 
    .f-28{ font-size: 25px; color: #84161D; }
  }
  .loading { display: flex; justify-content: center; align-items: center; }

  

  // .progress-bar {
  //   & { display: flex; justify-content: space-between; margin: 0 auto; margin-top: 10px; width: 100%; }
  //   .bar-section { 
  //     .progress-percentage { text-align: right; font-weight: 700; font-size: 21px; margin: 1rem 0 0.5rem 1rem; transition: all 0.4s linear; }
  //     .progress-grid { 
  //       display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
  //       .grid-square { width: 10px; height: 10px; margin: 2px; } 
  //     }
  //     .bar { height: 6px; margin: 0 5px 10px; }
  //     .section-title { width: 100%; font-family: $primaryfont; font-size: 16px; font-weight: 400; line-height: 19px; letter-spacing: 0px; text-align: center;  max-width: 171px; margin: 0 auto; }
  //   }

  //   .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
  //   .custom-bar .MuiLinearProgress-bar { background-color: #cbcb00; width: 102%; left: -2px; }
  //   .sections { & {}
  //     .section-name { font-size: 18px; margin: 15px auto 5px; }
  //   }
  //   .si_ass_result{
  //     &{ height: 105px; }
  //   }
  // }
  .progress-bar-content-container {
    & { margin: 20px auto 8px; padding: 40px 20px 40px; box-sizing: border-box;;width: 95%; }
      .progress-bar-main{display: flex;gap: 40px;align-items: center;justify-content: center;}
    .progressbar-container {
      & { }
      .progressbar-score-name { margin: 7px 0; padding:0 10px; font-family: $helvetica; font-size: 16px; font-weight: 600; font-stretch: normal; font-style: normal; line-height:35px; letter-spacing: normal; text-align: left; color:#747474; margin:0px; }
      .progress-linear-bar {
        & { max-width:196px; width: 90%; margin:10px;height: 9px; }
        .si_ass_result { 
          & { height: 9px; background: #e9e9e9; border-radius: 10px; }
          .progress-linear-opacity-filter {
            & { display: flex; align-items: center; border-radius: 10px; }
            .transparent {
              & { display: flex; align-items: center; justify-content: flex-end; border-top-left-radius: 10px; border-bottom-left-radius:10px; }
            }
            .opaque{border-radius: 10px;}
          }
        }
      }
      .progressbar-icon-and-section-name {
        & { display: flex; align-items: center; gap: 6px; }
        svg { font-size: 12px; }
        .section-name { font-family: $fontPTSerif; font-size: 12px; font-weight: normal; font-stretch: normal; font-style: italic; line-height: 1.5; letter-spacing: normal; text-align: left; color: #747474; }
      }
      .msi_score { font-weight:700;font-size:14px;font-family:$fontRaleway; color: #585858; }
      .progressbar-items{display: flex;align-items: center;}

    }.action-btn{&{margin:30px auto;text-align: center;}
    .download-result-btn{ background-color:#792140; color: #FFF; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight:700; line-height: normal; letter-spacing: 1px; text-transform:capitalize; padding: 10px 35px; height: 50px; border-radius:25px; margin:0 auto; }
      }
      hr{background: rgba(0, 0, 0, 0.10);width: 246px;height: 1px;border: none;}
       .tooltip-bottom{position: relative;}
        .tooltip-bottom::before { content: "";position: absolute;display: block; top: -96px; border: 10px solid transparent; border-bottom: 21px solid #f0f0f0; transform: translate(50%, calc(100% + 5px)); height: 17px;margin-left:111px;
        }
       .progressbar-contents{ &{max-width: 259px; width:100%; min-height: 480px;border-radius: 10px;background: #F6F6F6; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);padding:10px;}
       .content-para{ &{padding:10px;position: relative;bottom: -40px;}
       p{ color: #797979; font-family:$helvetica; font-size: 18px;font-style: normal; font-weight: 400; line-height: 28px; margin: 0px; }  
       a{ color: #792122; font-family:$helvetica; font-size: 17px;font-style: normal; font-weight: 600; line-height: 28px; text-decoration-line: underline; }
      
      }
    }
  }
  .progressbar-score-indicator-icon { & {  max-width: 654px; width: 95%; display: flex; justify-content: space-around; margin: auto; }
    svg { font-size: 15px; }
    .score-details-and-desc-container {
      .score-name-icon-container { & { display: flex; align-items: center; gap: 10px; justify-content: center; }
        .score-icon { 
          & { display: flex; align-items: center; }
          img { width: 12px; height: 12px; }
        }
      }
      p { margin: 0; }
    }
  }
  .progress-percent-text { transition: 0.4s linear; font-family: $helvetica; font-size: 18px; font-weight: 700; color: #cbcb00; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 3ch; max-width: calc(100% - 2ch); }
  .current-section { font-weight: 700; font-size: 21px; line-height: 25.2px; text-align: center; letter-spacing: 1px; text-transform: uppercase; color:#1B608A; font-family: $helvetica }

  .section-progress-top { max-width: 1050px; width: 95%; margin: 25px auto 45px; }

  .pre-additional-info { & { width: 97%; max-width: 1100px; padding: 15px 30px 30px; margin: 30px auto; box-sizing: border-box; background-color: white; text-align: center; }
    h1 { font-size: 24px; font-weight: 400; margin: 15px auto; padding: 30px 30px 10px; }
    p { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .radio { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .MuiTypography-root { font-size: 20px; font-weight: 300; max-width: 30ch; font-family: $primaryfont; }
    .Mui-checked { color: #F4D62B; }
    .Mui-checked + .MuiTypography-root { font-weight: 600; font-size: 19px; }
    .MuiFormControlLabel-root { width: 23ch; margin: 0 auto; }
  }
  .pre-additional-info, .pre-additional-questions-details, .assessment-additional {
    .step-buttons { & { display: flex; justify-content: space-around; align-items: center; max-width: 85%; margin: 15px auto; padding: 20px 15px; }
      .cta-prev { padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 400; font-family: $primaryfont; }
      // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }
      .cta { display: block; background-color: #ED2028; border-radius: 5px; color: #fff; text-decoration: none; width: auto; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 7px 18px; margin-left: 0; transition: 0.3s;height:50px; }
      .cta.Mui-disabled { background-color: #D8D8D8; color: #979797; }
      .cta-active { background-color: $color2; color: white;}
      .cta-disabled {background-color: #D8D8D8; color: #979797; }
      .cta:hover { background-color: #B0AC24; transition: 0.3s; color: #fff; }
      // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
      // .nextbtn:hover { background-color: $color2; }
      // .nextbtn.Mui-disabled { background: #C1C1C1; }
      .all-set-btn { font-size: 21px; /* padding: 7px 30px; */ background-color: #ED2028; color: #FFFFFF; }
    }
    .end-note{font-weight: 400; font-style: italic; font-size: 16px; line-height: 34px; text-align: center; color: #343333;}
  }
  

  .dashboard-heading-container { 
    .dashboard-heading { font-family: $fontPTSerif; font-size: 36px; font-weight: 700; line-height: 31px; text-align: center; color: #747474; }
    .selection-form { 
      & { display: flex; align-items: center;  margin: 20px auto; justify-content: center; text-align: center; font-family: $primaryfont; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; width: 40%; }
      .form-text { flex: 0 1 100%; text-align: center; font-weight: 600; font-size: 21px; color: #747474; }
      .form-details-text { 
        & { border: 0px solid #efefef; border-bottom: 4px solid #3A6891; padding: 3px 7px; max-width: 90%; text-align: center; }
        .MuiInput-root { 
          & { font-size: 21px; font-family: $fontRaleway; font-weight: 600; }
          .select-custom-icon {
            & { position: absolute; right: -13px; padding: 5px 10px; }
            img { width: 14px; height: 9px; }
          }
        }
      }
    }
  }

  .results {
    & { background: #fff; color: $textcolor1; max-width:100%; width: 106%; margin: 0; box-shadow: none;  }
    

    .banner-img{width: 100%;}
    .results-details-container {
      & { padding: 100px 15px 30px; width: 96%; margin: 20px auto 30px; position: relative; }
      .loading {
        & { width: 100%; background-color: transparent; }
      }
      .result-details-container-header-content { &{ max-width: 1100px; margin: 0 auto; margin-bottom: 100px; }
        .results-people-pattern-header {
          p { text-align: center; }
        }
        h1 { text-align: center; color: #84161D; font-family: $helvetica; font-size: 36px; font-weight: 700; line-height: 31px; margin: 0px 0px; padding: 0px; }
        .name { text-transform: capitalize; margin-top: 0; }
        p { text-align: center; font-family: $helvetica; font-size: 24px; font-weight: 300; line-height: 31px; color: #121212; margin: 20px auto; max-width: 75ch; }
      }
      .result-people-pattern-tabs-content-container { 
        .results-people-pattern-header { 
          p { text-align: center; color: #747474; font-family: $fontPTSerif; font-size: 18px; font-weight: 500; margin: 54px 5px 20px; }
        }
        .result-people-pattern-tabs { 
          .tabs-container {
            & { margin-bottom: 20px; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 8px; }
            .tab { 
              & { cursor: pointer; background: #fff; color: #808080; padding: 5px 14px; font-weight: 600; border-radius: 5px; font-size: 14px; }
              p { margin: 0; }
            }
            .tab-mentor { border: 2px solid #88A2C7; }
            .tab-administrator { border: 2px solid #BA837E; }
            .tab-catalyst { border: 2px solid #BCC793; }
            .tab-relational { border: 2px solid #978FB2; }
            .tab-overseer { border: 2px solid #97BECE; }
            .tab-mentor.active { background: #88A2C7; color: #fff; }
            .tab-administrator.active { background: #BA837E; color: #fff; }
            .tab-catalyst.active { background: #BCC793; color: #fff; }
            .tab-relational.active { background: #978FB2; color: #fff; }
            .tab-overseer.active { background: #97BECE; color: #fff; }
          }
          .tab-text-container { 
            & { margin: auto; background-color: #E1E8F1; max-width: 650px; width: 98%; padding: 15px 36px 15px 25px; border: 1px solid #808080; border-radius: 5px; box-sizing: border-box; }
            .tab-text-header { 
              & { display: flex; align-items: center; gap: 6px; }
              img { margin-top: 1px; }
              h4 { color: #747474; font-size: 18px; margin: 0; }
             }
            p { color: #747474; font-family: $fontPTSerif; font-size: 14px; font-weight: 500; line-height: 24px; }
          }
        }
      }
      .result-details-container { & { background-color: #ffffff; width: 95%;  border-radius: 5px; margin: 40px auto; max-width: 990px; }
        .result-details-title { color: #FFFFFF; text-align: left; margin: 2px auto 2px 0; font-weight: 700; font-size: 18px; line-height: 24px; font-family: $helvetica }
        .result-details-content { & { background-color: #FFFFFF; padding: 0z;} 
          div{ & { display: flex; justify-content: flex-start; align-items: flex-start; gap: 16px; padding: 10px 0; flex-direction: column; }
            &:nth-of-type(even){ background-color: #F4F4F4; padding: 14.5px; width: 97%; }
            img{ width: 30px; }
            p{font-family: $helvetica; font-size: 18px; line-height: 24px; font-weight: 300; margin: 0;}
          }
          .content { display: flex; flex-direction: row; padding: 5px 15px; width: 97%;  }
        }
        .result-details-summary { padding: 16px 30px; }
      }
      .result-details-positive{border: 2px solid #87976E;}
      .result-details-positive .result-details-summary{background-color: #87976E;}
      .result-details-negative { border: 2px solid #84161D; }
      .result-details-negative .result-details-summary{background-color: #84161D;}

      .result-section-report{ &{ width: 95%; max-width: 900px; gap: 30px; display: flex; justify-content: flex-start; align-items: stretch; margin: auto; flex-wrap: wrap; margin-bottom: 100px; }
        .card{ &{ width: calc(100% / 3 - 20px); display: flex; flex-direction: column; justify-content: center; align-items: center; min-width: 275px; }
          .image-container{ &{ width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column; margin-bottom: 40px; }
            img{height: 120px; width: auto;}
            h1{font-size: 16px; font-weight: 300; line-height: 24px; font-family: $helvetica; margin: 10px auto;}
            p{width: 105px; font-size: 12px; line-height: 18px; font-weight: 700; font-family: $helvetica; border-radius: 50px; text-align: center; padding: 5px; margin: 0;}
            p.low-score{background-color: #84161D; color: #ffffff;}
            p.medium-score{background-color: #EBBD79; color: #ffffff;}
            p.high-score{background-color: #87976E; color: #ffffff;}
            .section-heading {
              & { display: flex; align-items: center; gap: 4px; position: relative; }
            }
          }
          .inner-card{ &{ padding: 20px 30px; background-color: #F7F4F4; border-radius: 10px; box-shadow: 0 4px 4px 0 #00000040; display: flex; flex-direction: column; justify-content: flex-start; align-items: center; gap: 20px; width: 100%; height: 100%; flex-shrink: 1; box-sizing: border-box; }
            .report{ &{ width: 100%; }
              h1 {
                & { font-size: 16px; font-weight: 700; line-height: 35px; color: #747474; margin: 0; width: 100%; text-align: center; }
                span { cursor: pointer; }
              }
              .progress-bar{ &{margin: 10px 0; width: 100%; background-color: #E8E8E8; height: 9px; border-radius: 10px; max-width: 100%;}
                .current-progress{height: 100%; border-radius: 10px;}
                .current-progress.low-score{background-color: #84161D;}
                .current-progress.medium-score{background-color: #EBBD79;}
                .current-progress.high-score{background-color: #87976E;}
              }
            }
          }
        }
      }
      .invite-head {
          & { display: flex; flex-direction:column; justify-content: center; align-items:center ;}
          h1 { & { font-weight: bold; text-shadow: 1px 0 black; font-size: 34px; margin-top: 80px; max-width: 847px; width: 90%; } 
          br { display: none; }
          p { font-size: 21px; max-width: 971px; width: 90%; }
        }
      }
      .invite-pl-container{
        & { max-width: 990px; width: 99%; margin: 60px auto 0; display: flex; gap: 50px; justify-content: space-between; align-items: center; }
        .container{ display: flex; flex-direction: column; justify-content: space-between; flex: 1; }
        h1 { font-weight: 700; }
        p { text-align: center; font-size: 18px; font-weight: 500; padding: 0 1rem; font-family: $helvetica; color: #0F131D; }
        // svg { margin-top: auto; }
        .dotted-line { height: 450px; border: 1px dotted black; }
        
        .copy-url-container { 
          &{display: flex;justify-content: center;align-items: center;}
          .download-qr{color: #84161D; border-radius: 20px; padding: 4px 20px; cursor: pointer; font-family: $helvetica; border: 1px solid #84161D;width: 55%; margin-bottom: 0;padding: 8px 0;font-weight: bold;font-size: 20px;}
          .copy-text { background-color: #84161D; color: #fff; border-radius: 20px; padding: 4px 20px; cursor: pointer; font-family: $helvetica; 
            width: 55%; padding: 8px 0;
            margin: 0;
          }
        }
      }
      .qr-recommend{
        & { justify-content: center; display: flex; margin: 65px 0; font-size: 21px; }
      }
      .view-dashboard-container{ &{background-color: #F9F9F9; max-width: 990px; width: 95%; padding: 50px; margin: 60px auto 0; box-sizing: border-box; border-radius: 5px; text-align: center;}
        h1 { font-family: $helvetica; font-weight: 700; font-size: 30px; line-height: 43px; color: #343333; text-align: center; }
        p { font-family: $helvetica; font-weight: 300; font-size: 21px; line-height: 32px; color: #343333; text-align: center; margin: 20px 0; }
        button { border: none; background-color: #84161D; color: #ffffff; border-radius: 100px; padding: 10px 15px; font-size: 21px; line-height: 30px; font-family: $helvetica; font-weight: 700; letter-spacing: 1px; cursor: pointer; }
        button:hover { background-color: #97A97C; transition: 0.3s; color: #fff; }
      }

      .download-results-cta-container {
        & { display: flex; align-items: center; justify-content: center; width: 95%; margin: auto; }
        button {
          & { margin: auto; padding: 10px 20px; background: #792140; color: #fff; border-radius: 25px; font-family: $helvetica; font-size: 21px; font-weight: 700; outline: none; border: none; cursor: pointer; line-height: 30px; }
        }
      }
      
      .parish-support { & { padding: 28px; border: 2px solid #84161D; max-width: 1200px; border-radius: 25px; margin: 140px auto 0; position: relative; padding-top: 0; } 
        .header { & { width: 100%; text-align: center; background-color: #fff; width: fit-content; margin: auto; margin-bottom: 40px; padding: 0 60px; }
          h1 { font-size: 36px; line-height: 43px; font-weight: 700; font-family: $helvetica; color: #84161D; padding: 0; margin: auto; max-width: 35ch; margin-top: -40px;}
          .parish-support-description { text-align: center; margin-top: 20px; font-family: $helvetica; }
        }
        .cards { & { display: flex; gap: 30px; align-items: stretch; justify-content: center;  }
          .card { & { background-color: #EFEFEF; border-radius: 5px; padding: 30px; padding-bottom: 120px; display: flex; justify-content: start; align-items: center; flex-direction: column; position: relative; }
            .image-container { & {  width: 100px; height: 100px; border-radius: 100px; background-color: #44546A; display: flex; justify-content: center; align-items: center; } 
              img { width: 75%; }
            }
            h1 { font-family: $helvetica; font-weight: 700; font-size: 21px; line-height: 30px; color: #343333; margin: 15px auto; }
            p { font-family: $helvetica; font-weight: 300; font-size: 18px; line-height: 31px; color: #121212; text-align: center; margin: 0; max-width: 29ch;}
            a { display: contents; }
            .btn-cta {display: block; padding: 10px 80px; background-color: #84161D; color: #ffffff; font-family: $helvetica; font-size: 21px; line-height: 30px; font-weight: 700; border: none; border-radius: 100px; margin-top: 20px; cursor: pointer; position: absolute; bottom: 30px; }
          }
        }
      }
    }

    .spacer { height: 20px; }
    .selection-form { 
      & { width: 50%; display: flex; align-items: center; flex-direction: column; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-family: $primaryfont; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; width: 60%; }
      .form-text { flex: 0 1 100%; text-align: center; font-size: 34px; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid $color2; padding: 3px 7px; max-width: 90%; text-align: center; }
        .MuiInput-root { 
          & { font-size: 36px; font-family: $primaryfont; }
          .select-custom-icon {
            & { position: absolute; right: -45px; padding: 5px 10px; }
            img { width: 24px; height: 14px; }
          }
        }
      }
    }

    .overall-score-subtext {
      & { width: auto; max-width: 90%; box-sizing: border-box; margin: auto; margin-bottom: 20px; margin-bottom: 50px; margin-top: 25px; }
      p { & { margin-top: 0; font-size: 21px; font-weight: 400; line-height: 28px; letter-spacing: 0px; text-align: center; color: #121212;}
        span{ font-weight: 700; }
      }
    }

    h1 {
      & {color: #343333;text-align: center; font-size: 30px; font-style: normal; font-weight: 400; line-height: 38px; font-family: $helvetica; }
      .user-name { text-transform: capitalize; }
    }
    
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: $color1; font-size: 18px; font-weight: 900; font-family: $primaryfont; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: $primaryfont; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      // background-image: linear-gradient(to right, #4298B5, #1FA099 62%, #2A9828 100%); 
      // background-image: linear-gradient(to right, #C2D7DD, #7CCEE7 62%, #00A3D4 100%);
      background: linear-gradient(90deg, rgba(220, 218, 218, 0.60) 0%, rgba(220, 220, 220, 0.49) 100%);
    }
    .progress-opacity-filter { position: relative; display: flex; max-width: 700px; height: 26px; bottom: -26px; z-index: 1; box-sizing: border-box; margin: 0 auto; }
    .si_ass_progress .progress-line { z-index: 1; width: 2px; height: 26px; content: ' '; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { z-index: 2; background-color: #FDBF04; border-radius: 8px; padding: 2px 5px; display: block; position: absolute; font-size: 27px; font-weight: 700; color: #000; bottom: 55px; left: 0; margin-left: -30px; min-width: 45px; text-align: center; }
    .si_ass_progress .score-arrow { z-index: 1; width: 20px; height: 20px; content: ' '; background-color: #FDBF04; left: 0; bottom: 50px; position: absolute; margin-left: -10px; transform: rotate(-45deg); }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }

    .pre-score-header { padding: 30px 15px 1.5rem; text-align: center; color: #4A4A4A; font-family: $primaryfont; font-size: 24px; font-weight: 600; margin: auto 0; }
    
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; font-size: 34px; background: #F1F1F1; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered { & { width: 96%; max-width: 840px; background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { text-transform: uppercase; background-color: #126496; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 305px; margin-left: 10px; text-align: right; font-weight: 400; text-transform: uppercase;  font-family: $primaryfont; font-size: 15px;  font-style: normal; font-weight: 700;  line-height: normal; }
        p { margin: 0; color: #27282A; margin: 0;color: #27282A;font-family: Inter;font-size: 14px;font-style: normal;font-weight: 400; line-height: 17.4px; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }
  }

  .results-details-container {
    & { padding: 60px 15px 60px; }
    .result-details-container-header-content {
      .results-people-pattern-header {
        p { text-align: center; }
      }
      h1 { text-align: center; color: #3A6891; font-family: $fontRaleway; font-size: 26px; font-weight: bold; line-height: 1.6; margin: 20px 0px; padding: 0px; }
      .name { text-transform: capitalize; margin-top: 0; }
      p { text-align: center; font-family: $fontPTSerif; font-size: 16px; font-weight: 500; line-height: 1.5em; color: #7A7A7A; margin: 0; }
    }
    .result-people-pattern-tabs-content-container { 
      .results-people-pattern-header { 
        p { text-align: center; color: #747474; font-family: $fontPTSerif; font-size: 18px; font-weight: 500; margin: 54px 5px 20px; }
      }
      .result-people-pattern-tabs { 
        .tabs-container {
          & { margin-bottom: 20px; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 8px; }
          .tab { 
            & { cursor: pointer; background: #fff; color: #808080; padding: 5px 14px; font-weight: 600; border-radius: 5px; font-size: 14px; }
            p { margin: 0; }
          }
          .tab-mentor { border: 2px solid #88A2C7; }
          .tab-administrator { border: 2px solid #BA837E; }
          .tab-catalyst { border: 2px solid #BCC793; }
          .tab-relational { border: 2px solid #978FB2; }
          .tab-overseer { border: 2px solid #97BECE; }
          .tab-mentor.active { background: #88A2C7; color: #fff; }
          .tab-administrator.active { background: #BA837E; color: #fff; }
          .tab-catalyst.active { background: #BCC793; color: #fff; }
          .tab-relational.active { background: #978FB2; color: #fff; }
          .tab-overseer.active { background: #97BECE; color: #fff; }
        }
        .tab-text-container { 
          & { margin: auto; background-color: #E1E8F1; max-width: 650px; width: 98%; padding: 15px 36px 15px 25px; border: 1px solid #808080; border-radius: 5px; box-sizing: border-box; }
          .tab-text-header { 
            & { display: flex; align-items: center; gap: 6px; }
            img { margin-top: 1px; }
            h4 { color: #747474; font-size: 18px; margin: 0; }
           }
          p { color: #747474; font-family: $fontPTSerif; font-size: 14px; font-weight: 500; line-height: 24px; }
        }
      }
    }
  }

  .dashboard-details-container {
    & { padding: 60px 15px 60px; }
    .result-details-container-header-content {
      .results-people-pattern-header {
        p { text-align: center; }
      }
      h1 { text-align: center; color: #3A6891; font-family: $fontRaleway; font-size: 26px; font-weight: bold; line-height: 31px; margin: 20px 0px; padding: 0px; }
      .name { text-transform: capitalize; margin-top: 0; }
      p { text-align: center; font-family: $fontPTSerif; font-size: 16px; font-weight: 500; line-height: 1.5em; color: #7A7A7A; margin: 0; }
    }
    .result-people-pattern-tabs-content-container { 
      .results-people-pattern-header { 
        p { text-align: center; color: #747474; font-family: $fontPTSerif; font-size: 18px; font-weight: 500; margin: 54px 5px 20px; }
      }
      .result-people-pattern-tabs { 
        .tabs-container {
          & { margin-bottom: 20px; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 8px; }
          .tab { 
            & { cursor: pointer; background: #fff; color: #808080; padding: 5px 14px; font-weight: 600; border-radius: 5px; font-size: 14px; }
            p { margin: 0; }
          }
          .tab-mentor { border: 2px solid #88A2C7; }
          .tab-administrator { border: 2px solid #BA837E; }
          .tab-catalyst { border: 2px solid #BCC793; }
          .tab-relational { border: 2px solid #978FB2; }
          .tab-overseer { border: 2px solid #97BECE; }
          .tab-mentor.active { background: #88A2C7; color: #fff; }
          .tab-administrator.active { background: #BA837E; color: #fff; }
          .tab-catalyst.active { background: #BCC793; color: #fff; }
          .tab-relational.active { background: #978FB2; color: #fff; }
          .tab-overseer.active { background: #97BECE; color: #fff; }
        }
        .tab-text-container { 
          & { margin: auto; background-color: #E1E8F1; max-width: 650px; width: 98%; padding: 15px 36px 15px 25px; border: 1px solid #808080; border-radius: 5px; box-sizing: border-box; }
          .tab-text-header { 
            & { display: flex; align-items: center; gap: 6px; }
            img { margin-top: 1px; }
            h4 { color: #747474; font-size: 18px; margin: 0; }
           }
          p { color: #747474; font-family: $fontPTSerif; font-size: 14px; font-weight: 500; line-height: 24px; }
        }
      }
    }
  }

  .members-answered {
    & { width: 96%; max-width: 1190px; background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "Lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
    .section { text-transform: uppercase; background-color: #126496; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px; border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
  }

  

  .thank-you {
    & { min-height: 380px; width: 95%; max-width: 1008px ;padding-bottom: 30px; background: #fff; margin-top: 40px; }
    .tick { & { text-align: center; padding: 60px 0 0;border-radius: 8px; }
      img { width: 57px; height: 57px; }
    }
    h1 { width: auto; text-align: center; margin: 2px auto; font-family: $primaryfont; font-size: 36px; font-weight: 700; line-height: 43px; color: #27282A; padding: 52px 15px 52px; max-width: 30ch; }
    // p { width: 65%; margin: 0 auto; text-align: $primaryfont; font-size: 21px; color: #202020; }
    p { width: 44ch; margin: 0 auto; text-align: center; color: #000000; font-family: $primaryfont; font-size: 22px;font-weight: 500; line-height: 1.46em; }
    .back-btn { 
      & { text-align: center; margin: 2px auto; }
      a { border-bottom: 2px solid #7CCEE7; text-transform: uppercase; font-weight: 700; cursor: pointer; text-decoration: none; color: #202020; font-size: 15px; line-height: 17px; }
    }
  }

  .invite-container {
    & { margin: 50px auto 15px; padding: 30px 30px 50px; font-family: $primaryfont; font-size: 21px; line-height: 32px; color: #343333; background-color: #FFFFFF; text-align: center; width: 96%; max-width: 1100px; box-sizing: border-box; border-radius: 10px; }
    h3 { font-weight: 700; font-size: 36px; line-height: 1.2; margin: 15px auto; font-family: $vesterbro; }
    p { max-width: 72ch; margin: 1rem auto; }
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 45px 0 30px; max-width: 1400px; margin: 0 auto; justify-content: center; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 302px; line-height: 30px; }
        span { color: #343333; font-weight: 700; }
        img { height: 75px; }
      }
    }
    .cta-btn {
        & { display: inline-block; margin-top: 5px; color: #fff; padding: 0; border-radius: 5px; width: 100%; text-align: center; }
        a { display: block; text-decoration: none; color: #fff; width: 100%; max-width: 280px; margin: 0 auto; }
        button { background-color: #17B582; color: #FFF; height: 50px; width: 100%; max-width: 280px; text-align: center; font-family: $primaryfont; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal; letter-spacing: 1px; text-transform: uppercase; padding: 7px 15px; border-radius: 8px; }
        button:hover { background-color: $color3; transition: 0.3s; color: #fff; }
      }
  }

  .result-text-container {
    & { margin: 60px auto 15px; padding: 20px 30px 20px; font-family: "Lato", sans-serif; font-size: 21px; line-height: 32px; color: #343333; background-color: #FFFFFF; width: 96%; max-width: 1090px; box-sizing: border-box; border-radius: 10px; }
    .result-text { & { width: 75%; margin: 0 auto; max-width: 53ch; }
      .heading { text-align: center; font-weight: bold; padding-bottom: 15px; font-family: $vesterbro; }
      ol { 
        &{ color: #343333; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 34px; margin-top: 0; margin-bottom: 0; }
        li { margin: 0; }
      }
      p { margin: 10px 0; font-size: 24px; }
    }
    .btn-container{ & { text-align: center; }
      .invite-cta-btn {
        & { display: inline-block; margin: 23px auto; color: #fff; padding: 0; border-radius: 5px; width: 100%; max-width: 280px; }
        a { text-decoration: none; }
        button { background-color: $color2; color: #FFF; text-align: center; font-family: $primaryfont; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal; letter-spacing: 1px; text-transform: uppercase; padding: 7px 15px; width: 100%; max-width: 250px; height: 50px; border-radius: 8px; }
        button:hover { background-color: $color3; }
      }
    }
    .scroll-dashboard-tip { & { text-align: center; }
      p { margin: 5px auto; color: #343333; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 28px; }
    }
  }

  .sample-dashboard-text { & { gap: 20px; color: white; margin: 0 20px; margin-bottom: 0; display: flex; justify-content: space-between; align-items: center; }
    .right-line{ max-width: 459px; width: 95%; height:3px; background-color: #00CDA7;}
    .left-line{ max-width: 459px; width: 95%; height:3px; background-color: #00CDA7;}
     h1 { color: white; min-width: 376px; font-family: $vesterbro; font-weight: 700; font-size: 36px; text-align: center; text-transform: uppercase; text-wrap: nowrap; }
  }

  .dashboard-report-img-container {
    & { max-width: 1100px; width: 100%; margin: 0 auto; }
    img { width: 100%; }
  }

  .team-container {
    & { max-width: 90%; width: auto; border-radius: 10px; margin: 50px auto; font-family: $primaryfont; font-size: 30px; line-height: 36px; color: #ffffff; text-align: center; font-weight: 400; }
    h3 { line-height: 45px; font-size: 30px; color: #ffffff; font-weight: 400; margin: 5px auto; }
    .cta-btn {
      & { width: 220px; background: $color1; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: $primaryfont; font-size: 18px; font-weight: bold; }
    }
  }

  .individual-group-container-box { background-color: #FFFFFF; max-width: 1190px; width: 95%; }
 
  .individual-group {
    & { max-width: 461px; width: 90%; margin: 30px auto 45px; display: flex; height: 49px; background: white; border-radius: 5px; }
     button { cursor: pointer; flex: 0 1 50%; border: 0px; background: white; font-family: $primaryfont; font-size: 18px; font-weight: 400; line-height: 22px; letter-spacing: 1px; text-align: center; padding: 10px 0; border: 2px solid #FFF; }
    .left { border-top-left-radius: 5px; border-bottom-left-radius: 5px; font-weight: 500; color: #000;transition: all  ease-in-out 0.2s; }
    .left:hover { /* background-color: #435B63; color: #ffffff; transition: ease-in-out 0.2s; border-color: $color2; */ }
    .right { border-top-right-radius: 5px; border-bottom-right-radius: 5px; font-weight: 500; color: #000; transition: all  ease-in-out 0.2s;background-color: #FFFFFF; }
    .right:hover { /* background-color: #435B63; color: #ffffff; transition: ease-in-out 0.2s; border-color: $color2; */ }

    .active { background-color:#7CCEE7; color: #ffffff; font-weight: 500; border-color: #7CCEE7; border-radius: 5px; }
  }

  .toggle-individual-group { display: none; }

  .dashboard {
    & { min-height: 630px; background-color: #fff; width: 100%; }
    h1 { width: auto; margin: 0 auto; color: #343333; text-align: center; font-family: $primaryfont; font-size: 30px; font-style: normal; font-weight: 400; line-height: 38px; padding: 45px 30px 30px; max-width: 65ch; }
    
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: $primaryfont; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: $primaryfont; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #C2D7DD, #7CCEE7 62%, #00A3D4 100%);
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .progress-opacity-filter { position: relative; display: flex; max-width: 700px; height: 26px; bottom: -26px; z-index: 1; box-sizing: border-box; margin: 0 auto; }
    .si_ass_progress .progress-line { z-index: 1; width: 2px; height: 26px; content: ' '; background-color: transparent; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -58px; left: -100px; width: 50px; height: 30px;
      color: #fdbf04; font-size: 16px; font-weight: 600; font-family: $primaryfont;  
    }
    // .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: 700;line-height: 36px; color:  #22223B; bottom: 35px; left: 0; margin-left: -20px; }
    .si_ass_progress .msi_score { z-index: 2; background: #00676D; border-radius: 8px; padding: 2px 5px; display: block; position: absolute; font-size: 27px; font-weight: 500; color: #ffffff; bottom: 55px; left: 0; margin-left: -30px; min-width: 45px; text-align: center; }
    .si_ass_progress .score-arrow { z-index: 1; width: 20px; height: 20px; content: ' '; background: #00676D; left: 0; bottom: 50px; position: absolute; margin-left: -9px; transform: rotate(-45deg); }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    .pervalue { max-width: 700px; margin: -68px auto; display: flex; clear: both; color: $color3; font-size: 16px; }
    // .pervalue h4.nimp:after { content: '70%'; position: absolute; right: -19px; }
    // .pervalue h4.mexpe:after { content: '80%'; position: absolute; right: -19px; }
    // .pervalue h4.eexpe:after { content: '95%'; position: absolute; right: -19px; }
    
    // .result-sections { 
    //   & { width: 95%; max-width: 800px; margin: 50px auto; margin-bottom: 0; display: flex; justify-content: center; flex-wrap: wrap; }
    //   .score-section { 
    //     & { flex: 0 1 25%; text-align: center; font-weight: 700; font-size: 18px; margin: 10px auto; }
    //     .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 6px; }
    //     .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
    //   }
    // }

    .pre-score-header { padding: 30px 15px 1.5rem; text-align: center; color: #4A4A4A; font-family: $primaryfont; font-size: 24px; font-weight: 600; margin: auto 0; }
    // .result-text { text-align: $primaryfont; font-size: 24px; margin: 20px auto; max-width: 65ch; background-color: #FFFFFF; padding: 5px 30px; border-radius: 10px; color: #121212; }
    
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

    table { & { font-family: $primaryfont; border-collapse: collapse; width: 90%; max-width: 840px; margin: 0px auto 60px; font-size: 18px; font-weight: 400; color: #4A4A4A; }
      tr td:first-child { font-weight: 700; }
      tr:nth-child(odd) { background-color: #F4F4F4; border-color: #126496; }
      tr:nth-child(even) { background-color: #FBFBFB; border-color: #126496; }
      td, th { border: 1px #dddddd;text-align: left; padding: 16px 5px;; }
      thead th {
        & { font-size: 16px; line-height: 17px; font-weight: 700; color: #373737; }
        p { width: 100px; margin: auto; }
      }
      th.center-cell:last-child, td.center-cell:last-child { font-weight: 700; color: #4A4A4A; }
      .center-cell { text-align: center; text-transform: capitalize;line-height: 17px; }
      .medium { line-height: 22px; font-style: normal; color: #4A4A4A; white-space: nowrap; }
      .hash { width: 13px; }
      .avg { text-align: center; }
      .header { border-style: none; border-bottom: 3px solid #126496; background-color: transparent !important; }
      .footer { & { border-style: none; border-top: 3px solid #126496; color: #352E6C; }
        th.center-cell { color: #17B582; }
        .church-average { font-size: 16px; color: $textcolor1; font-weight: 700;line-height: 17px; }
      }
      // th p { white-space: nowrap; }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: "TRY Vesterbro"; font-size: 24px; font-weight: 700; color: #27282A;line-height: 38px; margin-top: 50px; text-align: center; }
    .most-answered {
      & { width: 96%; max-width: calc(840px); background: #FBFBFB; margin: 30px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 10px; overflow: hidden; border: 2px solid #73B76F; }
      // .green { background-color: #73B76F; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .green { background-color: #73B76F; padding: 10px 17px; margin: 0; border-radius: 0px; color: #FFF; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 700; line-height: normal; text-transform: uppercase; }
      // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .red { background-color: #F08D6E; padding: 10px 17px; margin: 0; border-radius: 0px; color: #FFF; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 700; line-height: normal; text-transform: uppercase; } 
      .most-answered-detail { padding: 10px 30px; width: calc(100% - 60px); color: #4C4C4C; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: normal; }
      .most-answered-detail:nth-child(odd) { background-color: #f4f4f4; }
      .sub-header {
        & { padding: 10px; border-bottom: 2px solid #73B76F; background-color: #F9F9F9; }
        p { margin: 0; color: #000; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 300; line-height: normal; }
      }
    }

    .areas-improved { 
      & { border-color: #F08D6E; }
      .sub-header { border-color: #F08D6E; }
    }

    .alignment { 
      & { width: 96%; max-width: 840px; margin: 0 auto 30px; background-color: $color1; margin: 50px auto 0; border-radius: 10px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 150px; background-color: #D8D8D8; border-radius: 10px; }
      .tab-buttons { 
        & { display: flex; width: 100%; margin: 0 auto; justify-content: center; background-color: #D8D8D8; border-radius: 10px 10px 0px 0px; padding: 10px 21px 0; box-sizing: border-box; }
        button { margin: 3px 2px 0; font-family: $primaryfont; color: #585252; font-size: 18px; border-radius: 9px 9px 0px 0px; font-weight: 600;line-height: 22px; text-transform: uppercase; text-align: center; padding: 15px 11px; background: none; border: 0px; width: 100%; cursor: pointer; transition: all ease-in-out 0.2s; }
        button:hover { color: #FFFFFF; background-color: $color3; transition: all ease-in-out 0.2s; }
        .active { color: #FFFFFF; background-color: #00676D; font-weight: 600;font-size: 18px;line-height: 22px;}
      }
      .tabs-content { flex-grow: 1; background-color: #00676D; padding: 10px; border-radius: 8px; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: 99%; background: #FBFBFB; margin: 10px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden;}
          // & { max-width: 99%; background: #FBFBFB; margin: 10px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden; }
          // .green { background-color: #73B76F; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 0 0;}
          .green-container { display: flex; justify-content: space-between; background-color: #73B76F; cursor: pointer; align-items: center; }
          .green { color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px; }
          .celebrateCurrentAreabtn { color: #FFFFFF; padding-right: 17px; }
          .red-container { display: flex; justify-content: space-between; background-color: #FF6645; cursor: pointer; align-items: center; }
          .red {color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius:9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px;}
          .analyzePotentialAreabtn { color: #FFFFFF; padding-right: 17px; }
          // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius:9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px;}
          // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px;}
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 400; font-size: 18px; color: $color3; padding: 10px 15px; width: calc(100% - 30px); }
            .answer { flex:-1 -1 200px; text-align: right; font-weight: 400; line-height: 18px; font-size: 16px; text-transform: uppercase; }
            .alignright { flex: 1 1 368px; }
            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; font-weight: 400; line-height: 24px; font-size: 16px; margin: 2px auto; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #17B582; font-size: 14px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { min-width: 14ch; max-width: 20%; padding: 5px; margin: 0;font-weight: 400; font-size: 16px; line-height: 18px; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail { font-size: 16px; line-height: 24px; font-weight: 400; color: black; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .mobile_scores {
      & { display: none; width: 90%; margin: 0 auto; }
      .individual {
        h2 { font-size: 18px; font-weight: 700; }
        & { font-size: 21px; font-weight: 400; border-bottom: 2px solid #00CDA7; }
        .row { 
          & { height: 40px; display: flex; align-items: center; border-top: 2px solid #00CDA7; }
          &:last-of-type { border-bottom: 2px solid #00CDA7; }
          .number { flex: 0 1 30px; font-size: 18px; }
          .name { flex: 1 1 auto; font-family: $primaryfont; font-size: 18px; font-weight: 700; }
          .percent { font-size: 18px; margin-right: 10px; color: #343333; font-weight: 700; }
          .toggle { 
            & { padding-top: 6px; color: $color2; }
            svg { font-size: 36px; }
           }
        }
        .scores { 
          & { font-size: 18px; border-top: 2px solid #00CDA7; }
          .section_rows { 
            & { display: flex; padding: 12px 16px; }
            &:nth-of-type(even) { background-color: #F4F4F4; }
            .section { flex: 1 1 auto; font-weight: 500; }
            .section_score { flex: 0 1 auto; font-weight: 400; }
          }
        }
      }
    }

    .sample-dashboard-text {
      & { margin-bottom: 20px; }
      h1 { min-width: 215px; color: #27282A; text-align: center; font-family: $primaryfont; font-size: 24px; font-style: normal; font-weight: 700; line-height: 38px; padding: 15px; }
      .left-line { height: 1px; background-color: #000; }
      .right-line { height: 1px; background-color: #000; }
    }
  }

  .dashboard-qa-container { 
    & { background-color: #F4F4F4; padding: 5px 15px; display: flex; justify-content: space-between; align-items: center; min-height: 45px; font-family: $primaryfont; }
    &:nth-child(odd) { background-color: #FBFBFB; }
    .dashboard-question { margin: 2px auto 2px 0; font-size: 16px; line-height: 21px; color: #27282A; text-align: left; width: 75%; }
    .dashboard-answer { margin: 2px 0 2px auto; font-family: $primaryfont; font-size: 16px; line-height: 21px; font-weight: 700; text-transform: uppercase; text-align: right; }
  }

  .results, .dashboard {

    .progress-bar { & { margin: 90px auto 51px; max-width: 90%; display: block; }}
    .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; position: relative; bottom: 88px; z-index: 1; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.progress-20-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-40-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-50-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-60-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-75-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-80-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }

    .pervalue h4 { position: relative; }
    .pervalue { max-width: 700px; margin: -68px auto; margin: 0 auto; display: flex; clear: both; color: #4A4A4A; font-size: 15px; position: relative; /* top: -20px; */ bottom: 118px; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.progress-0-percent:after { content: "0%"; position: absolute; top: 50px; right: -19px; font-weight: 700;}
    .pervalue h4.progress-20-percent:after { content: "20%"; position: absolute; top: 50px; right: -19px;  font-weight: 700;}
    .pervalue h4.progress-40-percent:after { content: "40%"; position: absolute; top: 50px; right: -19px;  font-weight: 700;}
    .pervalue h4.progress-50-percent:after { content: "50%"; position: absolute; top: 50px; right: -19px;  font-weight: 700;}
    .pervalue h4.progress-60-percent:after { content: "60%"; position: absolute; top: 50px; right: -19px; font-weight: 700;}
    .pervalue h4.progress-75-percent:after { content: "75%"; position: absolute; top: 50px; right: -19px; font-weight: 700;}
    .pervalue h4.progress-80-percent:after { content: "80%"; position: absolute; top: 50px; right: -19px; font-weight: 700;}
    .pervalue h4.progress-100-percent:after { content: "100%"; position: absolute; top: 50px; right: -12px; font-weight: 700;}
    // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
    .pervalue-text { & { position: relative; display: flex; margin: 0 auto; width: 100%; max-width: 700px; bottom: 70px; }
      p { font-family: $primaryfont; display: inline-block; text-align: center; margin: 0 auto; top: 12px; font-size: 18px; vertical-align: top; color: #575757; line-height: 16px; }
    }

    // .result-text { text-align: $primaryfont; font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px; max-width: 48ch; background-color: #EEEEEE; padding: 25px 30px; border-radius: 10px; color: #121212; }
    .result-text { font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px;max-width: 80ch; background-color: #EEEEEE; padding: 20px 30px; border-radius: 10px; color: #121212; width: 95%; box-sizing: border-box; }
    .result-text p { padding-top: 0; margin-block-start: 0; margin: 10px auto; }

    .score-section-container { & { grid-template-columns: repeat(4, 1fr); grid-template-rows: repeat(1, 1fr); display: grid; flex-direction: row; justify-content: center; padding: 0px 0 15px; margin: 0rem auto; width: 95%; }
      .score-section { 
        & { display: flex ; flex-direction: column; flex: 0 1 25%; width: 160px; text-align: center; color: #343333; font-size: 18px; font-weight: 700; margin: 20px auto; text-transform: uppercase; align-items: center; }
        .score-txt { font-size: 21px;line-height: 25px;font-weight: 700; color: #343333; font-family: $primaryfont; margin: 6px auto; max-width: fit-content; transition: all 0.4s linear; }
        .score-name { color: #343333; text-align: center; font-family: $primaryfont; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; text-transform: uppercase; margin: 15px auto 10px; max-width: 160px; text-wrap: nowrap; }
        .score-slider { & { width: 100%; margin: 0 auto; font-family: "Proxima-Nova", sans-serif; }
          .custom-bar { color: #352E6C; }
        }
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 10px; width: 100%; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color3; }
      }
    }

    .score-section-container.rounded{ display: none; }

    .invite-cta-btn { & { display: block; width: 100%; margin: 0px auto 15px; padding: 5px 15px 45px; color: #fff; border-radius: 5px; width: 100%; max-width: 280px; }
      a { display: block; text-decoration: none; color: #fff; }
      button { background-color: $color2; width: 100%; max-width: 100%; display: inline-block; padding: 5px 25px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; height: 50px; border-radius: 5px; }
      button:hover { background-color: $color3; transition: 0.3s; color: #fff; }
    }

  
    .charts-accordions-container { & { width: 96%; max-width: 860px; margin: 15px auto 30px; box-sizing: border-box; padding: 10px 10px 30px; }
      .MuiAccordion-root { color: #FFFFFF; margin: 10px auto; }
      .MuiAccordionSummary-root { background-color: #435B63; color: #FFFFFF; }
      .MuiAccordionDetails-root { padding: 15px 15px 45px; }
      .MuiAccordionSummary-content { & { color: #FFFFFF; font-size: 18px; line-height: 31px; margin: 5px auto; }
        p { margin: 0 auto 0 0; text-align: left; }
      }
      .MuiAccordionSummary-expandIconWrapper { color: #FFFFFF; }
      .accordion-chart-container { padding: 0px; box-sizing: border-box; margin: 15px auto; }

    }

    .hurdles-enablers {
      & { width: 95%; max-width: 840px; margin: 15px auto 15px; }
      h2 { font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 25px; text-align: left; margin: 15px auto; color: #202945; text-transform: uppercase; }
      .hurdles { background: #E5E5E5;  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
      .enablers { background: #E5E5E5;  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
      li { padding-bottom: 16px; font-weight: 400; font-size: 18px; }
    }
    // .urgency:last-child { padding-bottom: 75px; }
    .urgency {
      & { margin: 0 auto; padding: 0 0 15px; width: 100%; max-width: 840px; }
      h2 { font-family: $primaryfont; font-weight: 700; font-size: 16px; line-height: 25px; text-align: left; margin: 5px auto 15px; }
      .urgency-slider { 
        & { position: relative; background-color: #FFFFFF30; margin: 0 auto; padding: 115px 9% 80px; border-radius: 10px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 600; font-size: 15px; left: 7%; }

        .slider-labels {
          & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 9999; margin-left: -13px; margin-top: -12px; text-align: center; font-family: $primaryfont; font-size: 18px; font-weight: bold; color: #fff; }
        .top-labels { position: absolute; width: 1px; height: 40px; top: -46px; margin-left: -2px; background-color: #6E0CF9; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background-color: #6E0CF9; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { & { z-index: 1000; color: #6E0CF942; width: 100%; margin-left: 0px; }
        span.MuiSlider-track { left: -8px !important; }
      }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid #6E0CF960; height: 0; height: 2px; }

      .custom-slider .MuiSlider-thumb { background-color: #6E0CF9; }
      .custom-slider .MuiSlider-thumb:before { background-color: #6E0CF9; border: 4px solid #6E0CF9; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider.individual .MuiSlider-thumb:before { background-color: #6E0CF9; border: 4px solid #6E0CF9; width: 24px; height: 24px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #152036; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #152036; }
    }
  }

  .collective-leadership-scores-container {
    & { max-width: 850px; width: 95%; }
    .collective-leadership-scores-heading { color: #747474; text-align: center; font-family:$fontPTSerif; font-size: 36px; font-weight: 700; line-height: 31px; }
    .additional-questions-container {
      & { margin-top: 70px; }
      h3 { color: #4A4A4A; font-family: $fontRaleway; font-size: 16px; font-weight: 700; }
    }
    .urgency {
      & {width: 100%; max-width: 840px; }
      h2 { font-family: $primaryfont; font-weight: 700; font-size: 16px; line-height: 25px; text-align: left; margin: 5px auto 15px; }
      .urgency-slider { 
        & { position: relative; background-color: #fff; margin: 0 auto; padding: 115px 9% 36px; border-radius: 10px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 600; font-size: 15px; left: 7%; }

        .slider-labels {
          & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 9999; margin-left: -13px; margin-top: -12px; text-align: center; font-family: $primaryfont; font-size: 18px; font-weight: bold; color: #152036; }
        .top-labels { position: absolute; width: 1px; height: 40px; top: -46px; margin-left: -2px; background-color: #F8F8DE; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background-color: #F8F8DE; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { & { z-index: 1000; color: #6E0CF942; width: 100%; margin-left: 0px; }
        span.MuiSlider-track { left: -8px !important; }
      }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid #6E0CF960; height: 0; height: 2px; }

      .custom-slider .MuiSlider-thumb { background-color: #6E0CF9; }
      .custom-slider .MuiSlider-thumb:before { background-color: #F8F8DE; border: 4px solid #F8F8DE; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider.individual .MuiSlider-thumb:before { background-color: #6E0CF9; border: 4px solid #6E0CF9; width: 24px; height: 24px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #d5d5cb; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #152036; }
    }
  }
  
  .charts-accordions-container {
    & { max-width: 1190px; width: 96%; font-family: $primaryfont; margin-bottom: 20px; }
    .MuiPaper-root.MuiPaper-rounded { border-radius: 10px; background-color: transparent; box-shadow: none; }
    .result-typography { font-family: $primaryfont; text-transform: uppercase; font-size: 21px; font-weight: 600; line-height: 25px; }
    .MuiAccordionSummary-root { 
      & { background-color: #126496; border-radius: 10px; color: #fff; overflow: hidden; }
      &.Mui-expanded { border-radius: 10px 10px 0 0; }
      .MuiAccordionSummary-expandIconWrapper { 
        & { color: #FFF; }
        svg { width: 3rem; height: 3rem; }
      }
      .MuiAccordionSummary-content { margin: 5px auto; }
      .MuiAccordionSummary-content.Mui-expanded { margin: 0 auto; }
    }
    .MuiButtonBase-root.MuiAccordionSummary-root { min-height: 60px; }
    .MuiAccordionDetails-root {  padding: 0; display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 28px; }
    .result-container {
      & { max-width: 1100px; width: 100%; border: 2px solid #73B76F; border-radius: 10px; overflow: hidden; }
      .header {
        & { display: flex; align-items: center; background-color: #73B76F; padding: 10px 0 13px; }
        div{ padding-left:10px; color: white; font-size: 21px; font-weight: 700; text-transform: uppercase; word-wrap: break-word;} 
      }
      .description { & { height: auto; display: flex; align-items: center; background-color: #f9f9f9; border-bottom: 2px solid #73B76F; padding: 10px; }
        p { color: #000000; font-size: 21px; font-weight: 300; padding-left: 10px; margin: 0 auto 0 0; padding: 0; text-align: left; }
      }
      .content { & { width: 100%; }
        .item:nth-child(odd) { background-color: #F0F0F0; }
        .item:nth-child(even) { background-color: #F9F9F9; }
        .item {
          & { color: #4C4C4C; font-size: 21px; font-weight: 400; word-wrap: break-word; display: flex; justify-content: space-between; align-items: center; }
            .radio-question-container {}
            .item-description { padding: 10px 10px 10px 45px; }
            .item-status { color: black; font-size: 21px; padding: 10px; font-weight: 600; text-align: right; }
        }
      }
    }
    .accordion-details-container { display: flex; flex-direction: column; width: 100%; box-sizing: border-box; align-items: center; gap: 28px; background-color: #fff; padding: 30px 20px; border-radius: 10px; }


    .result-container-second {
      & { max-width: 1100px; width: 100%; border: 2px solid #F08D6E; border-radius: 10px; background: #F0F0F0; }
      .header { & { display: flex; align-items: center; background-color: #F08D6E; }
        div { padding-left:10px; color: white; font-size: 21px; font-weight: 700; text-transform: uppercase; word-wrap: break-word; } 
      }
      .description { & { display: flex; flex-direction: column; padding: 10px; border-bottom:  2px solid #F08D6E; justify-content: center; background-color: white; align-items: flex-start; line-height: 30px; }
        p { color: #000000; font-size: 21px; font-weight: 300; word-wrap: break-word; margin: 0px auto 0px 0px; }
        h4 { color: #000000;  font-size: 21px; font-weight: 600; word-wrap: break-word; margin: 0px; }
      } 
      .content { & { width: 100%; }
      input { border-radius: 50%; }
      
        .item { & { color: #4C4C4C; font-size: 21px; word-wrap: break-word; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
          .radio-question-container { & { display: flex; align-items: center; }
            input[type="radio"] { accent-color: #7CCEE7; height: 18px; width: 18px; min-width: 18px; margin: 0 5px 0 15px; appearance: none; -webkit-appearance: none; -moz-appearance: none; outline: none; background-color: white; border: 2px solid white; box-shadow: 0 0 0px 2px #B9B9B9; }
            input[type="radio"]:checked { background-color: #7CCEE7; border: 2px solid white; box-shadow: 0 0 0px 2px #B9B9B9; }
          }
          .wrapper { display: flex; align-items: center; flex-direction: row; gap: 11px; }
          .item-description { padding: 10px; }
          .item-status { color: black; padding: 10px; font-weight: 600; }
        }
      }
    }

    .result-container-third {
      & { max-width: 1100px; width: 100%; border: 2px solid #7CCEE7; border-radius: 10px; background: #F9F9F9; }
      .header {
        & { display: flex; align-items: center; background-color: #7CCEE7; padding: 10px 0 13px; }
        div{ padding-left:10px; color: white; font-size: 21px; font-weight: 700; text-transform: uppercase; word-wrap: break-word;} 
      }
      .accountability-content { & { padding: 16px 20px 20px; display: flex; flex-direction: column; gap: 15px; }
        .description { border-bottom: none; }
      }
      .description { 
        & { max-width: 1067px; display: flex; flex-direction: column; justify-content: center; }
        p { color: #000000; font-size: 21px; font-weight: 300; word-wrap: break-word;  padding: 0px 0px 0px 0px; margin: 0px 0px 2px 0px; line-height: 142%;}
        span { color: #000000; font-size: 21px; font-weight: 300; word-wrap: break-word; height: 25px; padding-left: 0px; margin: 0px 0px 10px 0px; line-height: 142%; }
        .bold { color: #000000; font-size: 21px; font-weight: 700; word-wrap: break-word; height: 25px; padding:2px 0px 0px 0px; margin: 0px 0px ; line-height: 142%; }
      } 
      .content {
        & { max-width: 1021px; width: 99%; height: auto; background: #fff; border-radius: 10px;  border: 1px solid #BABABA; }
        .content-items { 
          & { width: 100%; color: black;font-size: 21px; font-style: italic; font-weight: 300; line-height: 35.5px; overflow-wrap: break-word; margin: 12px auto; padding: 0 15px; box-sizing: border-box; }
          p { margin: 0; }
        }
      }
      .copy-clipboard {
        & { max-width: 250px; padding: 0; width: auto; border-radius: 5px;background: #7CCEE7; display: flex; justify-content: space-between; align-items: center; }
        div { color: white; font-size: 21px; font-weight: 600;line-height: 35.50px; word-wrap:break-word ; padding: 11px 13px 14px 0px; }
        img { height: 29px ;width: 29px; padding: 5px; }
        button { font-family: $primaryfont; color: #FFF; font-size: 21px; line-height: 35px; font-weight: 600; width: 100%; padding: 10px 15px; }
        button:hover { background-color: #000000; }
      }
    }   
  }
  
  .result-important-notice {
    & { display: flex; justify-content: space-between; gap: 4px; width: 97%; max-width: 1190px; margin: 45px auto 60px; }
    img { width: 38px; height: 38px; margin: 0 auto; }
    .result-important-notice-txt { color: #121212; font-size: 21px; font-style: normal; font-weight: 400; line-height: 34px; max-width: 1157px; width: 96%; margin: 0 auto; }
    span { color: #121212; font-size: 21px; font-style: italic; font-weight: 400; line-height: 34px; }
  }
  .footer-about {
    & { max-width: 1185px; width: 95%; border-radius: 10px; background-color: #B5E1EE; padding: 40px; box-sizing: border-box; }
    p { color: #121212; text-align: center; font-size: 24px; font-style: normal; font-weight: 400; line-height: 34px; margin: 0px auto; }
    p:nth-child(1) { max-width: 70ch; }
    p:nth-child(2) { max-width: 80ch; }
  }

main .charts-accordions-container .result-container .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{background-color: #792122;}

  .results .charts-accordions-container .MuiAccordionDetails-root { padding: 0; }
  .results .charts-accordions-container .accordion-chart-container { margin: 15px auto 0; }



  // .testimonial-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
  //   .avatar { margin-right: 53px; }
  //   .testimonial { 
  //     & { position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 21px; line-height: 1.35em; }
  //     .quotation-mark { position: absolute; margin-top: -50px; }
  //     .designation { 
  //       & { color: $color2; font-weight: 700; padding-top: 20px; }
  //       span {  font-weight: 400; } 
  //     }
  //   }
  // }
  .testimonial-container {
    // & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
    & {  margin: 30px auto 0; padding: 45px 2% 30px; font-family: $primaryfont; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
    
    .testimonial { 
      & { display: flex; flex-direction: column; position: relative; color: #ffffff; font-family: $primaryfont; font-size: 24px; line-height: 45px; text-align: center; width: 735px; }
      .testi { max-width: 70ch; text-align: center; color: #FFFFFF; font-family: $primaryfont; font-size: 19px; font-weight: 400; line-height: 1.2em; }
      .avatar {
        img { border-radius: 50%; padding: 10px; }
      }

      p { font-family: $primaryfont; color: #FFF; text-align: center; font-family: $primaryfont; font-size: 23px; font-style: normal; font-weight: 400; line-height: 43.5px;}
      .quotation-mark { & { display: block; text-align: left; font-size: 84px; color: $color1; margin-right: 15px; position: relative; line-height: 0; top: 10px; left: -5%; }
        img { height: 25px; width: auto; }
        // .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -15px; left: 2%; }
      .designation { 
        & { text-align: center; color: #fff; font-family: $primaryfont; font-size: 32px; line-height: normal; font-weight: 700; }
        span { font-weight: 300; font-style: normal; }
        .designation-inner { font-size: 18px; line-height: 22px; margin: 15px auto; font-weight: 600; }
      }
    }
  }

  .new-testimonial-container {
    // & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
    & {  margin: 30px auto 0; padding: 45px 2% 30px; font-family: $primaryfont; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
    .new-testimonial { 
      & { gap: 43px; display: flex; position: relative; font-family: $primaryfont; font-size: 24px; line-height: 45px; text-align: center; }
      .avatar {
        & { width: 156px; height: 156px; }
        img { border-radius: 50%; width: 100%; height: 100%; }
      }
      .testimonial-and-name-container { 
        & { display: flex; flex-direction: column; justify-content: space-around; max-width: 559px; width: 95%; }
        .testimonial { max-width: 70ch; text-align: center; color: #FFFFFF; font-family: $primaryfont; font-size: 19px; font-weight: 400; line-height: 1.2em; }
        .designation { 
          & { text-align: left; color: #3A6891; font-family: $fonteAleo; font-size: 21px; font-style: normal; font-weight: 700; line-height: normal; }
          .designation-inner { font-weight: 400; }
        }
      }
      p { color: #4A4A4A; font-family: $fontPTSerif; font-size: 21px; font-weight: 400; line-height: 28px; letter-spacing: 0px; text-align: left; margin-top: 0; }
      .quotation-mark { & { display: block; text-align: left; font-size: 84px; color: $color1; margin-right: 15px; position: relative; line-height: 0; top: 10px; left: -5%; }
        img { height: 25px; width: auto; }
        // .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -15px; left: 2%; }
    }
  }

  .resources-container {
    & { margin: 40px auto; font-family: $primaryfont; font-size: 24px; display: flex; width: 96%; max-width: 1040px; background-color: #FFFFFF; border-radius: 10px; box-sizing: border-box; }
    .people-container { & { width: 50%; display: flex; justify-content: center; align-items: center; }
      .people-img { width: 100%; height: auto; }
  }

    .resources-details { & { width: 50%; }
      .details-header { & { display: flex; justify-content: center; margin: 20px auto; padding: 0 5%; } 
        h1 { font-weight: 700; font-size: 36px; color: #202945; line-height: 50px; }
    }
      .details-paragraphs { & { margin: 25px auto; font-weight: 400; font-size: 18px; color: #202945; padding: 0 5%; line-height: 30px; }
      p { max-width: 42ch; }
      .paragraph-2  { margin-top: 25px; }
    }

    .resources-btn-container { & { margin: 40px auto; padding: 0 5%; } 
      .cta-btn { background-color: $color2; border-radius: 5px; color: #fff; text-decoration: none; width: 100%; max-width: 275px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; transition: 0.3s }
      .cta-btn:hover { background-color: #B0AC24; transition: 0.3s; color: #fff; }
  }
 
  }
    
  }

  .send-invite-container { & { max-width: 1191px; width: 95%; margin: 60px auto 10px; background-color: #FFFFFF; border-radius: 10px; font-family: $primaryfont; text-align: center; }
    .send-invite-header { & {  }  
      h1 {font-weight: 700; font-size: 30px; line-height: 43px; color: #202945; }
    }

    .send-invite-paragraph { & { }
     .send-invite-line-1 { margin-bottom: 0; padding: 0 10px; }
     .send-invite-line-2 { margin-top: 2px; padding: 0 10px; }
     p { font-size: 21px; font-weight: 400; line-height: 25px; }
  }

   .send-invite-btn-container { & { padding-top: 15px; padding-bottom: 35px; } 
    .cta { background-color: #7CCEE7; width: 193px; height: 50px; border-radius: 5px; color: #fff; text-decoration: none; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 400; transition: 0.3s; text-transform: uppercase; }
    .cta:hover { background-color: #000; transition: 0.3s; color: #fff; }
}



  }

  .results-footer-container {
    & { max-width: 597px; width: 95%; color: #202945; text-align: center; font-size: 30px; font-style: normal; font-weight: 400; line-height: 43px; }
    a { color: #126496; }
  }

  .testimonial-invite {
    & { font-family: $primaryfont; background-color: $color3; margin: 2rem; margin-top: 0; padding: 3rem; color: #fff; border-radius: 10px; font-size: 1.5rem; }
    p { max-width: 46ch; margin: 1rem auto 2rem auto; }
    .cta-btn { margin: 3rem auto 2rem auto; }
  }

  .invite-team-and-schedule-call-container {
    & { display: flex; gap: 13px; }
  }

  .schedule-container {
    & { box-sizing: border-box; border-radius: 20px; background: #3A6891; max-width: 441px; width: 95%; display: flex; justify-content: center; flex-direction: column; padding: 45px; }
    h3 { font-size: 36px; line-height: 10px; }
    .avatar { text-align: center; }
    p { color: #FFF; font-family: $fontPTSerif; font-size: 21px; font-style: normal; font-weight: 700; line-height: 42px; text-align: center; }
    .cta-btn {
      & { width: auto; display: inline-block; border-radius: 3px; background: #CC0; margin: 0 auto 0; color: #fff; padding: 0; }
      &:hover { background: $color2; color: $color3; transition: 0.3s; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: 220px; color: #fff; padding: 10px 0; font-family: $primaryfont; font-size: 16px; border-radius: 3px; font-weight: bold; }
    }
  }

  .legacy-invite-container {
    & { box-sizing: border-box; border-radius: 20px; background: #3A6891; max-width: 441px; width: 95%; display: flex; justify-content: center; flex-direction: column; padding: 28px; }
    h3 { font-size: 36px; line-height: 10px; }
    .avatar { text-align: center; }
    p { color: #FFF; font-family: $fontPTSerif; font-size: 21px; font-style: normal; font-weight: 700; line-height: 42px; margin: 20px 0; text-align: center; }
    .cta-btn {
      & { width: auto; display: inline-block; border-radius: 3px; background: #CC0; margin: 0 auto 0; color: #fff; padding: 0; }
      &:hover { background: $color2; color: $color3; transition: 0.3s; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: 220px; color: #fff; padding: 10px 0; font-family: $primaryfont; font-size: 16px; border-radius: 3px; font-weight: bold; }
    }
  }
  
  .invite-legacy-container {
    & { display: flex; justify-content: center; align-items: center; margin: 0 auto; }
    .invite-legacy { & { width: 100%; max-width: 1000px; box-sizing: border-box; background-color: #FFF; margin: 15px 25px; padding: 60px 20px 30px; font-family: $primaryfont; font-size: 24px; font-weight: 700; line-height: 36px; color: #202945; text-align: center; border-radius: 10px; }
      // p { margin: 0 auto; max-width: 45ch; padding: 0 30px; }
      h1 { font-size: 30px;line-height: 43px; }
      p { font-family: $primaryfont;line-height: 1.17em; max-width: 60ch; margin: 30px auto; font-weight: 400;font-size: 21px;padding: 0 2rem; }
      .cta-btn {
        & { width: 100%; max-width: 250px; background: #F18132; margin: 50px auto 50px; color: #fff; border-radius: 5px; transition: 0.3s; }
        &:hover { background: #fdbf04; color: $color3; transition: 0.3s; color: #fff; }
        a { display: block; text-decoration: none; color: #fff; }
        button { text-transform: uppercase;letter-spacing: 0; width: 100%; color: #fff; padding: 7px 5px; font-family: $primaryfont; font-size: 21px; border-radius: 3px; font-weight: 600; }
      }
    }
  }

}


footer { & { min-height: 100px; max-width: 1110px; margin: 0 auto; text-align: start; padding: 15px 30px 20px; box-sizing: border-box; }
  .bleat-logo img { height: 92px; width: auto; }
  .bleat-logo-max-padding img { height: 85px; width: 80px; padding: 10px 12px 100px 12px; }
  .bleat-logo-mobile { & { width: 100%; display: flex; justify-content: space-between; /*min-height: 70px; */}
      img { height: 69px; width: auto; }
    }
}
  
  .label-name { font-size: 14px; font-weight: 600; margin: 2px auto; }
  .label-percent { font-size: 18px; font-weight: 700; margin: 2px auto; }


  .chart-container { & { width: 96%; max-width: 1110px; border-radius: 10px; background-color: #FFFFFF; margin: 25px auto 5px; }
    .chart-heading { & { font-family: $primaryfont; margin: 25px auto 5px; justify-content: center; text-align: center; }
      h1 { padding: 30px 15px 0; font-weight: 600; font-size: 24px; line-height: 31px; color: #343333; margin: 15px auto 5px; max-width: 57ch; }
      h1.exception { font-weight: 600; }
      p { font-weight: 600; font-size: 24px; line-height: 31px; color: #334433; margin-top: 0; padding: 0 10px; }
      span { color: #EC1C24; font-weight: 700; }
    }

    // .chart-data{margin: 5px auto;}
    .step-buttons { & {width: 100%; display: flex;justify-content:space-between;margin: 5px auto; align-items: center; max-width: 85%; margin: 15px auto; padding: 30px 15px; }
    .cta-prev { padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 400; font-family: $primaryfont; }
    // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }
    .cta {border: none; padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 600; font-family: $primaryfont;
    }      
    .next-btn { border-bottom :1px solid #EC1C24; display: inline-block; }
    // .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
    // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
    // .nextbtn:hover { background-color: $color2; }
    // .nextbtn.Mui-disabled { background: #C1C1C1; }
    // .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }

@media only screen and (min-width: 650px) {

  // main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  // main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}

@media only screen and (max-width: 1250px) { 
  
  .custom-slider.MuiSlider-root { padding: 14px 0; }  

}

@media only screen and (max-width: 1110px) {
  
  main .dashboard .urgency { overflow: hidden; }
  main .testimonial-container .testimonial .testi { width: 90%; margin: auto;}
  main .testimonial-container .testimonial .testi br { content: ""; }
  main .testimonial-container .testimonial .testi br:after { content: " "; }
}

@media only screen and (max-width: 950px) {
  main .resources-container { & { flex-direction: column; }
    .people-container { & { width: 100%; max-height: 400px; overflow: hidden; align-items: flex-start; border-radius: 10px 10px 0 0; }
      img { object-fit: cover; object-position: top; }
    }
    .resources-details { & { width: 100%; }
      .details-header h1 { text-align: center; max-width: 28ch; }
      .details-paragraphs p { margin: 0 auto; text-align: center; }
      .resources-btn-container { text-align: center; }
    }
  }
}


@media only screen and (max-width: 830px) {

  main .current-section { margin: 10px auto 30px; width: 60%; color:#1B608A; text-align: center; font-size: 18px; font-style: normal; font-weight: 700; line-height:31px; text-transform: uppercase; }
  main .section-progress-top { margin: 25px auto 15px; }

  main .results .charts-accordions-container, main .dashboard .charts-accordions-container {
    padding: 0 0 45px;
  }
  .label-name { font-size: 14px; margin: 2px auto; }
  .label-percent { font-size: 16px; margin: 2px auto; }

  footer { & { min-height: 0; width: 100%; left: 0; }
  .bleat-logo{
    &{display:flex; justify-content: center;}
  }
    .bleat-logo-mobile { & { width: 100%; display: flex; justify-content: space-between; /*min-height: 70px; */}
      img { height: 42px; width: auto; }
    }
  }
  
  main {
    .container { margin-bottom: 15px; }
    .assessment-progress-sections-container {
    .main-sections-dots { & { min-width: 500px; }
    }
    .Leadership{margin-right:0;}
    .Evangelization{margin:0 auto;}
    .main-section-name { position: relative; text-align: center; text-transform: uppercase;left:-32%; }
    .main-section-name.finished { color: #FFFFFF; font-weight: 400; }
    .main-section-name.active { color: #FDBF04; font-weight: 700; }
    .main-section-name.unfinished { color: #FFFFFF; font-weight: 400; }
    .main-sections-names { & { position: relative; max-width: 1100px; height: 3px; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; width: 10ch; }
      }
    }
  }
    .intro {
      & { height: auto; }
      .sub-container {
        & { padding: 17px 16px 30px 16px; }
        .assessment-intro { height: auto; color: #000; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 30px; }
        .middle-content { height: auto; margin-top: 29px; margin-bottom: 19px; }
        .bottom-content { max-width: 755px; width: 93%; height: auto; 
          // .heading { color: #565656; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 30px; }
          .points { text-align: center; padding: 0 10px; }
        }
        .cta {
          button { width: 287px; }
        }
      }
    }
    .testimonial-container .testimonial { font-size: 21px; flex-direction: column; }
    .invite-legacy-container .invite-legacy { & { margin-bottom: 20px; font-size: 21px; margin: 1rem 1rem; padding: 0; }
      h1 { font-size: 26px; }
        p { font-size: 18px; }
    }
    .progress-bar-content-container {
      .progressbar-container {
        & { justify-content: space-between; gap: 10px; }
        .progressbar-score-name { font-size: 12px; }
        .progress-linear-bar { width: 50%; }
      }

      .progress-bar-main{display: flex;flex-direction: column;align-items: center;}
    }
    .progressbar-score-indicator-icon { 
      & { flex-direction: column; align-items: center; gap: 20px; }
    }
    .result-people-pattern-tabs-content-container {
      .tabs-container { 
        & { flex-direction: column; padding: 0 20px; }
        .tab { width: 100%; text-align: center; box-sizing: border-box; }
      }
    }
    .results .result-text, .dashboard .result-text { width: 95%; box-sizing: border-box; font-size: 20px; }
    .thank-you {
      h1 { font-size: 24px; line-height: 32px; }
      p { font-size: 16px; }
    }
  }
  main .logo-left-container{display: none; }
  
  main .charts-accordions-container .result-container-second .content .item .radio-question-container input[type=radio] {
    height: 15px; width: 15px; min-width: 15px;
  }
  
  
  main .results .selection-form { & { font-size: 28px; }
    .form-details-text .MuiInput-root { font-size: 28px; }
  }
  main .dashboard {
    table { & { font-size: 15px; }
      thead th { font-size: 14px; }
    }
  }

  main .results {
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { font-family: $primaryfont; font-size: 24px; font-weight: 400; line-height: 29px; letter-spacing: 0px; text-align: center; background: transparent; }
    .selection-form .form-text { font-size: 24px; }
    .invite-head {
      h1 { font-size: 18px !important; font-weight: 700 !important; } 
      p { font-size: 16px; font-weight: 300; text-align: center; }
    }
    .invite-pl-container {
      & { flex-direction: column; }
      .dotted-line { display: none; }
      svg { max-width: 500px !important; width: 90% !important; }
      .download-qr { width: 100% !important; }
      .copy-text { width: 100% !important; }
    }
    .qr-recommend { text-align: center; }
  }

  main .results, main .dashboard {
    .si_ass_progress .msi_score { font-size: 24px; }
  }

  main {
    .charts-accordions-container {
      & { width: 95%; }
      .MuiAccordionDetails-root{
        & { padding: 0; }
        .Mui-expanded {
          svg { font-size: 34px; }
        }
      }
      .accordion-details-container { gap: 10px; padding: 0; }
      .result-container { & { width: 96%; margin: 15px auto 8px; }
        .description {
          p { color: #000; font-family: $primaryfont; font-size: 18px; font-style: normal; font-weight: 300; line-height: normal; }
        }
        .content {
          .item {
            .item-description { color: #4C4C4C; font-size: 18px; padding: 10px 10px 10px 10px; }
            .item-status { font-size: 16px; min-width: 8ch; }
          }
        }
        .header div { font-size: 18px; }
      }
      .result-container-second .description h4 { font-size: 18px; }
      .result-container-third {
        .accountability-content { padding: 10px; }
        .description { padding: 0; border-bottom: none; }
        .content { & { width: 100%; }
          .content-items { width: 96%; margin: 10px auto; font-size: 18px; line-height: 25px; }
        }
        .copy-clipboard { & { max-width: 220px; }
          img { height: 20px; width: auto; }
          button { font-size: 18px; padding: 7px 10px; }
        }
      }
      .result-typography { font-size: 18px; font-weight: 600; line-height: 21px; }
    }
    .result-important-notice { & { width: 96%; margin: 30px auto 45px; }
      img { width: 24px; height: 24px; margin: 5px auto 0; }
      .result-important-notice-txt { & { font-size: 18px; margin: 0 auto 15px; line-height: 27px; }
        span { font-size: 18px; }
      }
    }
    .footer-about { & { padding: 30px; }
      p { font-size: 21px; }
    }
    .send-invite-container {
      .send-invite-header h1 { font-size: 24px; line-height: 32px; margin: 30px auto 15px; }
      .send-invite-paragraph p { font-size: 18px; max-width: 60ch; margin: 5px auto; }
    }
    .results-footer-container { & { font-size: 24px; line-height: 34px; }}
    .invite-team-and-schedule-call-container {
      & { flex-direction: column; }
      .schedule-container { margin: auto; }
      .legacy-invite-container { margin: auto; }
    }
    .new-testimonial { 
      & { flex-direction: column; align-items: center; }
      .testimonial-and-name-container { 
        .testimonial { 
          p { text-align: center; }
        }
        .designation { display: flex; justify-content: center; }
      }
    }
  }
  
  .intro-container { & {}
    .intro { padding: 30px 15px; min-height: unset; background-image: url("../src/Assets/Images/intro-bg-mobile.png"); }
    .intro-heading { & { margin: 10px auto; }
      h1 { line-height: 35px;font-size: 28px; }
    }
    .intro-para { & { margin: 10px auto; }
      .para-one{ font-size: 20px;line-height: 30px; }
      .para-two{ font-size: 20px;line-height: 30px; }
    }
    .cta {
      .cta-btn { & {line-height: 22px;font-size: 18px;}
      // &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
  }

  
  .chart-container { & {}
    .chart-heading{ & { font-family: $primaryfont; margin: 25px auto 5px; justify-content: center; text-align: center;}
      h1 { padding-top: 25px; font-weight: 600; font-size: 21px; line-height: 31px; color: #343333; }
      p { font-weight: 600; font-size: 21px; line-height: 31px; color: #343333; padding: 0 10px; }
      span { color:#EC1C24; font-weight: 700; }
    }

  // .chart-data{margin: 5px auto;}

    .step-buttons { & { width: 100%; display: flex; justify-content:space-between; margin: 25px auto; align-items: center; max-width: 85%; padding: 10px 25px 15px; }
    .cta-prev { padding: 11px 25px; color: #182036; font-size: 16px; font-weight: 400; font-family: $primaryfont; }

    // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }

    .cta {
      & { max-height: 50px; background-color: #ED1824; font-weight: 700; font-size: 21px;line-height: 25.2px; color: #FFFFFF; padding: 11px 18px; }
      &:hover { background: #435B63;transition: 0.3s; color: #fff; }
      }
      .next-btn{border-bottom: none;}

    // .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
    // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
    // .nextbtn:hover { background-color: $color2; }
    // .nextbtn.Mui-disabled { background: #C1C1C1; }
    // .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }
}



@media only screen and (max-width:1065px) {
  main .results .score-section-container, main .dashboard .score-section-container {
    // grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(1, 1fr);
    display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: wrap;
  }
}

@media only screen and (max-width:950px) {
  main .results .results-details-container .parish-support .cards .card .btn-cta { padding: 10px 60px; }
}
@media only screen and (max-width:750px) {
  main .results .results-details-container .parish-support .cards .card .btn-cta { padding: 10px 40px; }
}

@media only screen and (min-width: 650px) {
  
  // main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  // main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }
  
}

@media only screen and (max-width: 650px) {
  main { flex-direction: column; }
  main .assessment-progress-sections-container {
    .main-sections-dots { & { min-width: 400px; }
    }
  }
  main .pre-additional-info { & { padding: 15px; } h1 { font-size: 21px; } p { font-size: 18px; } }  

  main .results, main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { width: 85%; position: relative; margin: 45px auto 5px; max-height: 90px; }
    .si_ass_progress .score-arrow { width: 14px; height: 14px; margin-left: -7px; bottom: 27px; }
    .si_ass_progress .msi_score { font-size: 24px; padding: 2px 5px; border-radius: 2px; bottom: 30px; }
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

  }
  main .results .si_ass_progress .progress-line { bottom: 0; }
  main .results, main .dashboard { & {  }
    .pervalue { 
      & { font-size: 9px; bottom: 88px;}
      h4:after { right: -16px !important; }
    }
    .si_pro_header { 
      & { bottom: 58px;}
      h4:before { height: 15px !important; }
    }
    .pervalue-text { & { bottom: 65px; margin: 10px auto; position: relative; width: 100%; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 10px; line-height: 10px; color: #4A4A4A; }
    }
    .result-text { font-size: 18px; padding: 15px 18px; margin: 40px auto 20px; }
    .score-section-container {
      // & {grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr);flex-wrap: wrap; padding: 0 0 20px 0; max-width: 90%; margin-top: 0; }
      & { grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr); }
      .score-section { 
        & { margin: 15px auto; width: 100%; max-width: 260px; }
        .score-txt { font-size: 18px; font-weight: 700; margin: 7px auto; }
        .score-name { font-size: 16px; font-weight: 700; min-width: 24ch; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto 7px; max-width: 250px; }
        
      }
    }
    .members-answered .section { text-transform: uppercase; font-weight: 400; }
  }
  
  main .invite-container { & { font-size: 1.1rem; /* color: white; */ margin: 0 auto; }
    .invite-container-spacer { padding: 1rem; 
      h3 { font-size: 24px; line-height: 43px; margin: 1.5rem auto; padding: 0 15px; }
      p { font-size: 21px; max-width: 25ch; }
      .invite-reasons { & { flex-wrap: wrap; padding: 20px 0 20px; }
        .reason { & { flex: 0 1 100%; font-size: 21px; line-height: 28px; max-width: 27ch; margin: 15px auto; }
          .height30 { height: 15px; }
        }
        .reason span { /* color: $color1; */ }
        .reason img { } 
      }
    }
  }
  main .testimonial-container .testimonial .quotation-mark img { height: 18px; width: auto; }
  main .testimonial-container .testimonial { & { color: #fff; margin: 20px auto 0; text-align: center; }
    p { color: #FFF; text-align: center; font-family: $primaryfont; font-size: 18px; font-style: normal; font-weight: 400; line-height: 35px; }
    .quotation-mark { color: $color1; font-size: 46px; }
    .testi { font-size: 18px; }
    .designation { color: #FFF; 
      span { color: #fff; font-size: 18px; }
    }
  }
  
  main .testimonial-invite {
    & { background-color: #DCDBDB; margin: 0 auto; padding: 3rem 2rem; color: #27282A; border-radius: 10px; font-size: 21px; font-weight: 700; }
    p { max-width: 40ch; margin: 1rem auto; line-height: 43px; }
    .cta-btn { margin: 2rem auto 0rem auto; }
  }
  main .results .members-answered { & { margin: 15px auto; }
    .accordion-container { & { background-color: #00676D; }
      .accordion-title { color: #FFFFFF; text-align: left; margin: 2px auto 2px 0; text-transform: uppercase; }
      .accordion-expand-icon { color: #FFFFFF; }
      .accordion-content { background-color: #FFFFFF; padding: 0; }
      .accordion-summary { & { min-height: 48px; }
        .Mui-expanded { margin: 5px auto; }
      }
      .inner-accordion-container { & { background-color: #FFFFFF; color: #000000; margin: 0 auto; }
        .inner-accordion-summary { & { margin: 0 auto; min-height: 30px; padding: 5px 15px 5px; border-top: 1px solid black; }
          .MuiAccordionSummary-content { margin: 0 auto; }
          .Mui-expanded { margin: 0 auto; min-height: 24px; }
        }
        .inner-accordion-title { & {color: #000000; text-align: left; margin: 2px auto 2px 0; text-transform: uppercase; font-size: 16px; }
          .true { color: #73B76F; }
          .neutral { color: #FFB545; }
          .false { color: #FF6645; }
        }
        .inner-accordion-expand-icon { color: #ABAD23; }
        .inner-accordion-content { background-color: #FFFFFF; font-size: 16px; border-top: 1px solid black; }
      }
    }
  }
  main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .alignment .tab-buttons button { font-size: 16px; padding: 10px 12px; background-color: #97979733; }
    .alignment .content .align-details .answered-detail .more-details .more-row { 
      & { justify-content: space-between; }
      .answer { font-size: 14px; }
    }
    .alignment { & { width: 95%; }
      .container-tabs { background-color: #F2F2F2; }
      .tab-buttons { & { flex-wrap: wrap; padding: 10px; box-sizing: border-box; background-color: #F2F2F2; }
        button { & { font-size: 15px; min-width: 55px; padding: 10px 5px; flex: 0 1 47%; border-radius: 3px; color: #343333; }
          &.active { color: #FFFFFF; background-color: #00676D; font-size: 15px; }
        }
      }
      .tabs-content { background-color: #F2F2F2; padding: 0 10px 10px; }
      // .content .align-details .answered-detail:nth-child(even) { background-color: #FBFBFB; }
      .content .align-details .green-mobile.answered-detail { & { padding: 0; width: 100%; }
        .true, .false { text-align: left; border-bottom: 1px solid $textcolor1; border-top: 1px solid $textcolor1; padding: 7px 15px 5px; }
        p { flex: auto; padding: 7px 15px; }
      }
    }
    .additional-questions-container { & {}
      .answers-list { & { padding: 10px 10px 10px 35px; }
        li { font-size: 16px; }
      }
      .urgency .urgency-slider h3 { margin: 5px auto; }
    }
    .overall-score-subtext { & { background-color: #F4F4F4; padding: 15px; margin: 15px auto; border-radius: 10px; }
      p { font-size: 18px; line-height: 28px; margin: 0px auto; }
    }
  }
  
  main .invite-legacy-container .invite-legacy { & { padding: 3rem 1rem; }
    .cta-btn { margin: 2rem auto 0 auto; }
  }
  main .resources-container {
    .people-container { max-height: 300px; }
    .resources-details .details-header h1 { font-size: 24px; line-height: 28px; }
  }

  .chart-container { & { margin: 15px auto; width: 95%; }
    .chart-heading h1 { font-weight: 600; }
    .chart-heading h1.exception { font-weight: 600;  }
  // .chart-data{margin: 5px auto;}

    .step-buttons { & {padding: 1px 25px 15px; margin: 1px auto;}
      .cta-prev { padding: 5px 25px;margin-bottom: 15px; }
      .cta {margin-bottom: 15px; }
    // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }

// .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
// .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
// .nextbtn:hover { background-color: $color2; }
// .nextbtn.Mui-disabled { background: #C1C1C1; }
// .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }
  
  main .results .progress-opacity-filter { height: 14px !important; bottom: -14px !important; }
  main .results .progress-opacity-filter .opaque { height: 14px !important; }
  main .results .progress-opacity-filter .transparent { height: 14px !important; }
  main .results .si_ass_progress { height: 14px !important; }
  main .results .results-details-container .invite-pl-container .copy-url-container { 
    & { flex-direction: column; background-color: transparent; padding-top: 2rem; }
    p { background-color: #F0F0F0; margin-bottom: -20px; height: 40px; display: flex; align-items: center; justify-content: center; } 
  }

  main .dashboard .si_ass_progress { height: 11px !important; }
  main .dashboard .progress-opacity-filter { height: 11px !important; bottom: -11px !important; }
  main .dashboard .progress-opacity-filter .transparent, .opaque { height: 11px !important; }
  .progress-linear-opacity-filter .opaque { height: auto !important; }
  main .results .results-details-container .parish-support .cards .card .btn-cta { padding: 10px 60px; }
}

// @media only screen and (max-width: 640px) {
//   main .results .results-details-container .parish-support .cards .card .btn-cta { padding: 10px 40px; }
// }

@media only screen and (max-width: 550px) {
  .slider-container {
    .slider {
      .slider-points {
        & { margin-left: 2%; }
      }
    }
  }
  

  .chart-container .chart-heading p { max-width: 36ch; margin: 0px auto 5px; }

  main .charts-accordions-container .accordion-details-container { padding: 0 5px; }

  main .pre-additional-info .step-buttons, main .pre-additional-questions-details .step-buttons, main .assessment-additional .step-buttons { padding: 15px 0; max-width: 90%; }

  
  main .results { width: 95%; margin: 15px auto 0 auto; }
  main .results .selection-form { width: max-content; max-width: 90%; flex-wrap: wrap; }
  main .results .selection-form .form-detail {
    & { width: 100%; margin-right: 0; }
    .form-details-text { max-width: 100%; }
  }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; font-family: $primaryfont; font-size: 18px; font-weight: 400; line-height: 22px; letter-spacing: 0px; text-align: center; 
  }
  main .results h1 { color: #000; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 28px; }
  main {
    .results .result-text, .dashboard .result-text { & { margin: 40px auto 20px; font-size: 18px; line-height: 24px; }
      p { margin: 6px auto; }
    }
  }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main .invite-container .cta-btn { & { width: 100%; max-width: 90%; } 
    button { font-size: 18px; padding: 8px 16px; line-height: 16px; width: 100%; }}
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar, main .dashboard .progress-bar { & { width: 90%; } }
  main .results .si_ass_progress .msi_score { font-size: 14px !important; min-width: 20px !important; text-align: center; margin-left: -18px !important; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }
  main .results .score-section-container { & { grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr); }
    .score-section { width: 100%; max-width: 90%; }
  }

  main {
    .charts-accordions-container {
      .result-typography { font-size: 14px; line-height: 18px; }
      .result-container .header div { font-size: 14px; }
      .result-container .description p { font-size: 14px; line-height: 18px; }
      .result-container .content .item .item-description { max-width: 230px; width: 96%; font-size: 14px; line-height: 18px; padding: 10px 5px 10px 10px !important; }
      .result-container .content .item .item-status { font-size: 12px; line-height: 15px; text-align: center; padding: 10px 5px; max-width: 47px; width: 95%; }
      .result-container-second .description h4 { font-size: 14px; line-height: 18px; }
      .result-container-second .content .item {  }
      .result-container-third .description p { line-height: 22px; }
      .result-container-third .content .content-items { width: 100%; padding: 0 10px; font-size: 14px; line-height: 24px; font-weight: 300; }
    }
    .result-important-notice { margin: 15px auto; }
    .result-important-notice .result-important-notice-txt { & { font-size: 14px; line-height: 34px; }
      span { font-size: 14px; }
    }
    .footer-about {
      & { padding: 15px; }
      p { font-size: 14px; line-height: 34px; }
      p:nth-child(1) { max-width: 42ch; }
    }
    .results-footer-container { & { font-size: 21px; line-height: 28px; } }
  }

  main .send-invite-container { & { margin: 30px auto 15px; }
    .send-invite-header h1 { font-size: 21px; line-height: 28px; }
    .send-invite-paragraph p { font-size: 18px; line-height: 26px; }
    .send-invite-btn-container { & { width: 100%; box-sizing: border-box; padding: 10px 10px 30px; }
      .cta { width: 100%; max-width: 90%; }
    }
  }

  main .thank-you .tick{ margin: 60px auto 30px; padding: 0; }
    
  main .invite-container { max-width: 95%; font-size: 18px; padding: 0 15px 30px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container .invite-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

  main .team-container { width: 95%; max-width: 29ch; font-size: 18px; line-height: 35px; font-weight: 700; }
  main .team-container h3 { font-size: 18px; line-height: 35px; font-weight: 700; }

  main .schedule-container h3 { font-size: 24px; }
  // main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonial-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonial-container .testimonial .designation { & { font-size: 21px; }
    .designation-inner { color: #FFF; text-align: center; font-family: $primaryfont; font-size: 14px; font-style: normal; font-weight: 600; line-height: 17px; margin: 10px auto; max-width: 30ch; width: 90%; }
  }
  main .testimonial-container .testimonial .designation span { padding-top: 6px; }
  
  main .invite-legacy-container .invite-legacy { & { font-size: 21px; margin: 15px 12px; }
      p { padding: 0 15px; } }
  
  main .thank-you { width: 95%; border-radius: 0px; }
  main .thank-you h1 { font-size: 24px; font-family: $primaryfont; padding: 0px 10px 45px 10px; margin: 0px auto; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 17px; }
  main .testimonial-container .testimonial { font-size: 18px; width: 90%; margin: 0px auto 0; text-align: center; }
  
  main .MuiSwitch-thumb { background-color: #00CDA7 !important; position: relative; }
  main .MuiSwitch-thumb::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); content: ""; width: 3px; height: 3px; border: 3px solid #FFFFFF; border-radius: 50%; padding: 2px; background-color: transparent !important; }
  main .MuiSwitch-track { background-color: #E7E9EB !important; opacity: 1 !important; }
  main .individual-group { display: none; }
  main .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
  main .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
  main .toggle-individual-group .active { font-weight: 400; color: $color2; color: #ffffff; }
  .dashboard-heading-container {
    .selection-form { & { flex-direction: column;  }
      .form-detail { width: 80% !important; }
    } 
  }
  main .dashboard { width: 95%; margin-top: 15px; }
  main .dashboard h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-sections { margin: 50px auto; padding-bottom: 0; }
  main .dashboard .result-sections .score-section { flex: 0 1 50%; }
  main .dashboard .result-sections .score-section .score-txt { margin: 10px auto; }
  main .dashboard .result-sections .score-section .score-name { font-weight: 400; margin: 10px auto 0; }
  main .dashboard .si_ass_progress .msi_score { font-size: 14px; min-width: 30px; text-align: center; margin-left: -20px !important; }
  main .dashboard hr { display: none; } 
  main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 15px; padding: 10px 15px; width: 100%; box-sizing: border-box; }
  main .dashboard .alignment .content .align-details .answered-detail { font-size: 16px; }
  main .dashboard .hurdles-enablers ul { & { padding-left: 30px; } 
    li { font-size: 14px; } }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 90%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; color: #17B582; }
  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .results .selection-form .form-details-text .MuiInput-root .select-custom-icon {
    & { position: static; }
    img { width: 18px; height: 11px; }
  }
  main .dashboard .alignment .content .align-details.analyze .answered-detail { flex-direction: row; }
  main .results, main .dashboard {
    .hurdles-enablers {
      h2 { font-size: 16px; }
      li { font-size: 16px; }
    }
    .urgency {
      h2 { font-size: 16px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #6E0CF930; }
      .urgency-slider { margin: 0; background-color: #FFFFFF30; }
      .urgency-slider.individual { padding-top: 45px !important; }
      .custom-slider.MuiSlider-root { color: transparent; }
      .custom-slider .MuiSlider-track { border: 0px; }
      .MuiLinearProgress-root.custom-bar { background-color: #6E0CF930; height: 8px; margin-top: -22px; border-radius: 5px; width: calc(100% + 3px); margin-left: -2px; }
      .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }
    }
  }

  main .results-details-container { padding: 60px 15px 0 15px; }
  main .results .results-details-container { width: auto; padding: 60px 0; }
  main .results .results-details-container .result-section-report .card .image-container { margin: 0; }
  main .results .results-details-container .result-section-report .card .inner-card .report h1 { padding: 12px 0; }
  main .results .results-details-container .result-section-report .card .inner-card { margin-bottom: 30px; }
  main .results .results-details-container .result-details-container-header-content { margin: 40px auto; padding: 2rem; }
  main .results .results-details-container .bg-class { background: #F6F6F6;border-radius: 20px; }
  main .results .results-details-container .result-details-container-header-content h1 { font-size: 21px; }
  main .results .results-details-container .result-details-container-header-content p { font-size: 16px; margin-bottom: -20px; }
  main .results .results-details-container .invite-pl-container .copy-url-container .copy-text { width: 60%; height: 50px; border-radius: 25px; display: flex; align-items: center; justify-content: center; font-size: 18px; }
  main .results .results-details-container .result-details-container .result-details-content div:nth-of-type(even){
    width: auto;
  }
  main .results .results-details-container .parish-support .cards .card .btn-cta { padding: 10px 80px; }
}

@media only screen and (max-width: 550px) {

  .intro-container {
    .intro-heading { max-width: 32ch; margin: 10px auto; padding: 15px 15px 0;
      h1 { line-height: 25px; font-size: 21px; }
    }
    
    .intro-para { max-width: 30ch; margin: 10px auto;
      .para-one{ font-size: 18px; line-height: 22px; margin: 0 auto 10px; }
      .para-two{ font-size: 18px; line-height: 22px; }
    }
    .cta {
      .cta-btn { & { line-height: 25px; font-size: 21px; width: auto; margin: 75px auto 45px; padding: 7px 15px; }
        // &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
  }
  main .resources-container .people-container { max-height: 235px; }

  .MuiLinearProgress-root.custom-bar { height: 7px !important; border-radius: 10px; }
  
  main .sample-dashboard-text {
    & { margin: 0 10px; }
    h1 { font-family: $primaryfont; font-size: 13px; font-weight: 700; line-height: 43px; letter-spacing: 0px; text-align: center; min-width: 147px; }
  }
  main .sample-dashboard-text .right-line { background-color: #FFFFFF; height: 1px; width: 30%;}
  main .sample-dashboard-text .left-line { background-color: #FFFFFF; height: 1px; width: 30%;}

  main .result-text-container { & {  }
    .result-text { & { width: auto; font-family: $primaryfont; font-size: 18px; }
      ol { & { padding-left: 24px; max-width: 28ch; margin: 5px auto; }
        li { font-size: 18px; line-height: 28px; }
      }
    }
    .btn-container { & { width: 100%; }
      .invite-cta-btn { & {width: 100%; max-width: 100%; }
        a { width: 100%; max-width: 100%; }
        button { width: 100%; }
      }
    }
  }
  main .result-text-container .result-text p{ font-size: 24px; line-height: 28px; }
  main .result-text-container .scroll-dashboard-tip{ font-size: 18px; line-height: 21px;
    p { color: #343333; text-align: center; font-family: $primaryfont; font-size: 18px; font-style: normal; font-weight: 400; line-height: 28px; }
  }

  .dashboard-report-img-container { & { max-width: 1110px; width: 95% !important; margin: 20px auto !important; text-align: center; } }

  main .start .intro .footnote { box-sizing: border-box; margin: 0 auto; width: 96%; text-align: left; padding: 30px 10px 15px 10px; }
  main .assessment-progress-sections-container {
    .main-sections-dots { & { min-width: 300px; }
    }
  }
}

@media only screen and (max-width: 450px) {
 
  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }
  main .assessment-progress-sections-container {
    .main-sections-dots {
    .main-section-left { right: -45px; left: auto;}
    }
  .main-section-name { font-size: 14px; left: -30%; }
}
}
@media only screen and (max-width: 400px) {
 
  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }
  main .assessment-progress-sections-container {
    .main-sections-dots { & { min-width: 250px; max-width: 300px; }
    .main-section-left { right: -35px; left: auto;}
    }
  .main-section-name { font-size: 12px; font-weight: 700; left: -25%; }
}
}


// Completion Bar
.completion-bar { 
  & { background-color: #E8E8E8; width: 98%; height: 8px; border-radius: 10px; margin: auto; display: flex; align-items: center; }
  .bar { border-radius: 4px; height: 8px; }
}

// Milestone Bar
.milestone-bar-cover{
  & { margin-bottom: 6rem; }
  .milestone-bar {
    & { position: relative; background-color: #84161D; height: 3px; width: 100%; margin-top: 2rem; }

    .circle { height: 13px; width: 13px; border-radius: 50%; background-color: #84161D; }
    .triangle { width: 0; height: 0; border-top: 7px solid transparent; border-bottom: 7px solid transparent; border-left: 14px solid #84161D; }
    
    p { font-weight: 300; text-align: center; transform: translateX(-45%); font-size: 21px; color: #343333; padding-top: 10px; }
    .location { display: none; }
    .dot-line { background-image: linear-gradient(to bottom, #989898 20%, rgba(255, 255, 255, 0) 0%); background-position: left; background-size: 4px 10px; background-repeat: repeat-y; }

    .lookers { position: absolute; top: -5px; left: 5%; }
    .on-shore { position: absolute; top: -5px; left: 15%; }
    .casting-off { position: absolute; top: -5px; left: 43%; }
    .raising-sails { position: absolute; top: -5px; left: 68%; }
    .sailing-waves {  position: absolute; top: -5px; left: 84%; }
    .beacon-parish { position: absolute; top: -5px; left: 95%; }

    .triangle-one{ position: absolute; top: -5px; left: 28%; }
    .triangle-two{ position: absolute; top: -5px; left: 57%; }
    .triangle-three{ position: absolute; top: -5px; left: 99.3%; }
  }
  .active {
    .circle { background-color: #fff; }
    p { font-weight: 700; font-size: 24px; color: #1B608A; }
    .location { display: block; object-fit: cover; width: 50px; height: 100%; margin-top: 0; position: absolute; top: -114px; left: -19px; }
    .location-img { object-fit: contain; width: 100%; margin-top: 6rem; }
  }
}
@media only screen and (max-width: 1400px){
  .dashboard-overview .overview-board .boxes .box .active p { font-size: 18px; width: 15ch; }
}

@media only screen and (max-width: 1250px){
  .milestone-bar-cover .milestone-bar p { font-size: 16px; }
  .dashboard-overview .overview-board .boxes .box .active p { font-size: 16px; width: 9ch; }
}

@media only screen and (max-width: 950px){
  .milestone-bar-cover .milestone-bar p { width: 9ch; }
}

@media only screen and (max-width: 790px){
  .milestone-bar-cover .milestone-bar p { width: 9ch; font-size: 12px; }
  .dashboard-overview .overview-board .boxes .box .active p { font-size: 13px; }
}

@media only screen and (max-width: 600px){
  .milestone-bar-cover{margin-bottom: 4rem;}
  .milestone-bar-cover .milestone-bar p { font-size: 14px; }
  .dashboard-overview .overview-board .boxes .box .active p { font-size: 13px; }
}
@media only screen and (max-width: 480px){
  .result-section-report{justify-content: center !important; }
  .dashboard-overview .overview-board .boxes .box .active p  { font-size: 13px; }
  .milestone-bar-cover{
    .active{
      p{font-size: 12px !important;}
      .location-img{width: 14px;height: 18px;margin-left: 1rem;margin-top: 7rem;}
    }
    .milestone-bar{
      & { height: 1px; }
      .casting-off {
        & { top: -3px; }
      }
      .lookers {
        & { top: -3px; }
      }
      .on-shore  {
        & { top: -3px; }
      }
      .sailing-waves {
        & { top: -3px; }
      }
      .beacon-parish {
        & { top: -3px; }
      }
      p {font-size: 9px !important;}
      .circle{width: 6px;height: 6px;}
      .triangle {
        & { border-top: 3px solid transparent; border-bottom: 3px solid transparent; border-left: 6px solid #84161D; top: -2px; }
      }
    }
  }
  .milestone-bar-cover .milestone-bar p {  }
}