.score-container { 
  & { display: flex; justify-content: center; gap: 1rem; }
  .card {
    & { margin: 0 1rem 4rem; padding-top: 0; display: flex; flex-direction: column; justify-content: center; align-items: center; }
    .image {
      & { display: flex; flex-direction: column; align-items: center; }
      img { width: 120px; height: 120px; margin-top: 2rem; }
      p { text-transform: uppercase; }
      .medium-score { text-transform: none; }
    }

    .low-score { background-color: #84161D; padding: 2px 6px; border-radius: 10px; text-align: center; color: #fff; font-size: 12px; }
    .medium-score { background-color: #FDBF04; padding: 2px 6px; border-radius: 10px; text-align: center; color: #fff; font-size: 12px; }
    .high-score { background-color: #97A97C; padding: 2px 6px; border-radius: 10px; text-align: center; color: #fff; font-size: 12px; }

    .completion-bars-container { 
      & { background-color: #f7f4f4; border-radius: 10px; min-height: 315px; min-width: 300px; margin: auto; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; padding: 0 16px; }
      p { font-weight: 700; color: #747474; font-size: 17px; display: flex;justify-content: center; text-align: center; padding-bottom: 0rem; margin-bottom: 0.6rem; }
      span { font-weight: 300; }
      .completion-bar { 
        & { background-color: #E8E8E8; width: 75%; height: 8px; border-radius: 10px; margin: auto; margin-bottom: 2rem; }
        .completion { border-radius: 10px; height: 8px; }
      }
    }
    .completion-bars-container::before { content: " "; }
  }
}

@media only screen and (max-width: 1290px){
  .score-container { flex-wrap: wrap; }
}  