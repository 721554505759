.dashboard-overview { 
  & { width: 100%; display: flex; background-color: #fff;  }
  * { box-sizing: border-box; }
  .overview-board { 
    & { border-top-left-radius: 1rem; background-color: #F5F5F5; width: 100%; }
    .image { display: flex; flex-direction: column; justify-content: center; align-items: center; }
    .overview-header{
      & { display: flex; padding-left: 3%; border-top-left-radius: 1rem; gap: 1.5rem; background-color: #84161D; }
      .over-tab { font-weight: 300; color: #fff; margin-top: 0.8rem; margin-bottom: 0.8rem; cursor: pointer; }
      .active { font-weight: 700; border-bottom: 1px solid #fff; }
    }
    .loading { width: 100%; height: 100%; background-color: #fff; }
    .boxes { 
      & { height: calc(100vh - 136px); overflow-y: scroll; }
    .sub-heading { font-weight: 300; font-size: 18px; color: #595959; line-height: 31px; padding-left: 3%; }
    .box{
      & { max-width: 100%; width: 94%; margin: 1rem auto; background-color: #fff; border-radius: 1rem; padding-top: 0.8rem; padding-bottom: 1rem; }
      .path-bar {
        & { position: relative; background-color: #84161D; height: 3px; width: 100%; margin-top: 2rem; }

        .circle { height: 13px; width: 13px; border-radius: 50%; background-color: #84161D; }
        p { font-weight: 300; text-align: center; transform: translateX(-45%); }
        .location { display: none; }
        .dot-line { height: 2px; width: 4px; background-color: #989898; margin-top: 0.4rem; }

        .lookers { position: absolute; top: -5px; left: 5%; }
        .on-shore { position: absolute; top: -5px; left: 15%; }
        .casting-off { position: absolute; top: -5px; left: 43%; }
        .raising-sails { position: absolute; top: -5px; left: 68%; }
        .sailing-waves {  position: absolute; top: -5px; left: 84%; }
        .beacon-parish { position: absolute; top: -5px; left: 95%; }
      }
      .active {
        .circle { background-color: #fff; }
        p { font-weight: 700; font-size: 20px; color: #1B608A; }
        .location { display: block; object-fit: cover; width: 24px; height: 100%; margin-top: 0; position: absolute; top: -102px; left: -8px; }
        .location-img { object-fit: contain; width: 100%; margin-top: 6rem; }
      }

      .path-img { object-fit: contain; width: 100%; }

      // img { object-fit: contain; width: 100%; margin-top: 6rem; }
      .about {
        & { max-width: 100%; width: 80%; margin: auto; text-align: center; padding-bottom: 3rem; }
        h3 { color: #84161D; font-size: 24px; font-weight: 700;line-height: 1.6; }
        p { font-weight: 300; color: #121212; }
      }
      h2 {text-align: center; color: #84161D; }

      .chart-container { 
        & { display: flex; align-items: center; justify-content: center; gap: 2rem; }
        .info {
          & { display: flex; align-items: center; justify-content: center; }
          .parishioner-type {
            & { padding: 32px 16px; background: #F7F4F4; border-radius: 10px; font-size: 17px; }
            .header {
              & { color: #84161D; font-weight: 700; }
            }
            p { max-width: 27.5ch; font-weight: 300; }
          }
        }
      }

      .green-container {
        & { border: 2px solid #87976E; border-radius: 5px; width: 80%; margin: auto; }
        h4 { background-color: #87976E; color: #fff; margin: 0; padding: 0.8rem 2rem; }
        div { 
          & {display: flex; align-items: center; margin: 0; }
          p { padding-left: 6px; padding-right: 6px; }
          img { width: 28px; object-fit: contain; height: 100%; margin-top: 0; margin-left: 1rem; }
        }
        div:nth-child(even){background-color: #FBFBFB;}
        div:nth-child(odd){background-color: #F4F4F4;}
        .content-div {
          & { display: flex; flex-direction: column; align-items: flex-start; }
        }
      }
      .red-container {
        & { border: 2px solid #84161D; border-radius: 5px; width: 80%; margin: auto; margin-top: 2rem; }
        h4 { background-color: #84161D; color: #fff; margin: 0; padding: 0.8rem 2rem; }
        div { 
          & {display: flex; align-items: center; margin: 0; }
          p { padding-left: 6px; padding-right: 6px; }
          img { width: 28px; object-fit: contain; height: 100%; margin-top: 0; margin-left: 1rem; }
        }
        div:nth-child(even){background-color: #FBFBFB;}
        div:nth-child(odd){background-color: #F4F4F4;}
        .content-div {
          & { display: flex; flex-direction: column; align-items: flex-start; }
        }
      }
      .invite-pl-container{
        & { max-width: 990px; width: 99%; margin: 60px auto 0; display: flex; gap: 50px; justify-content: space-between; align-items: center; }
        .container{ display: flex; flex-direction: column; justify-content: space-between; flex: 1; }
        h1 { font-weight: 700;text-align: center; }
        p { text-align: center; font-size: 18px; font-weight: 500; padding: 0 1rem; font-family: $helvetica; color: #0F131D; }
        // svg { margin-top: auto; }
        .dotted-line { height: 450px; border: 1px dotted black; }
        
        .copy-url-container { 
          &{display: flex;justify-content: center;align-items: center;}
          .download-qr{color: #84161D; border-radius: 20px; padding: 4px 20px; cursor: pointer; font-family: $helvetica; border: 1px solid #84161D;width: 55%; margin-bottom: 0;padding: 8px 0;font-weight: bold;font-size: 20px;}
          .copy-text { background-color: #84161D; color: #fff; border-radius: 20px; padding: 4px 20px; cursor: pointer; font-family: $helvetica; 
            width: 55%; padding: 8px 0;
            margin: 0;
          }
        }
      }
      .qr-recommend{
        & { justify-content: center; display: flex; margin: 24px 0; font-size: 21px; }
      }
      }
     }
  }

  .qr-container {
    & { background: #FFFFFF; border-radius: 20px; margin: 2rem auto; width: 94%; }
    .invite-head {
      & { display: flex; flex-direction:column; justify-content: center; align-items:center ;}
      h1 { & { font-weight: 700; text-shadow: 1px 0 black; font-size: 30px; margin-top: 80px; max-width: 100%; width: 90%; } 
        br { display: none; }
        p { font-size: 21px; max-width: 971px; width: 90%; font-weight: 300; color: #343333; }
      }
      .invite-para { max-width: 70%; }
  }
  .invite-pl-container{
    & { max-width: 990px; width: 99%; margin: 60px auto 0; display: flex; gap: 50px; justify-content: space-between; align-items: center; }
    .container{ display: flex; flex-direction: column; justify-content: space-between; flex: 1; }
    h1 { font-weight: 700; font-size: 21px; text-align: center; }
    p { text-align: center; font-size: 18px; font-weight: 500; padding: 0 1rem; font-family: $helvetica; color: #0F131D; }
    // svg { margin-top: auto; }
    .dotted-line { height: 450px; border: 1px dotted black; }
    
    .copy-url-container { 
      &{display: flex;justify-content: center;align-items: center;}
      .download-qr{color: #84161D; border-radius: 20px; padding: 4px 20px; cursor: pointer; font-family: $helvetica; border: 1px solid #84161D;width: 55%; margin-bottom: 0;padding: 8px 0;font-weight: bold;font-size: 20px;}
      .copy-text { background-color: #84161D; color: #fff; border-radius: 20px; padding: 4px 20px; cursor: pointer; font-family: $helvetica; 
        width: 55%; padding: 8px 0;
        margin: 0;
      }
    }
  }
  .qr-recommend{
    & { justify-content: center; display: flex; margin: 24px 0; font-size: 21px; padding-bottom: 30px; }
  }
  }
}

@media only screen and (max-width: 1000px) {
  .dashboard-overview .overview-board { width: calc(100vw - 12rem); }
}

@media only screen and (max-width: 900px) {
  .dashboard-overview .overview-board { width: calc(100vw - 11.5rem); }
  .dashboard-overview .overview-board .boxes .box .chart-container { flex-direction: column; }
}

@media only screen and (max-width: 680px) {
  .dashboard-overview .overview-board { width: calc(100vw - 11rem); }
}

@media only screen and (max-width: 600px) {

  // .sidebar { display: none !important; }

  .dashboard-overview .overview-board {
    & { max-width: 100%; width: 100%; }
    .milestone-bar-cover {
      .milestone-bar {
        & { height: 1px; }
        p { font-size: 9px; }
        .casting-off {
          & { top: -3px; }
        }
        .lookers {
          & { top: -3px; }
        }
        .on-shore  {
          & { top: -3px; }
        }
        .sailing-waves {
          & { top: -3px; }
        }
        .beacon-parish {
          & { top: -3px; }
        }
        .circle { 
          & { height: 6px; width: 6px; }
        }
        .triangle {
          & { border-top: 3px solid transparent; border-bottom: 3px solid transparent; border-left: 6px solid #84161D; top: -2px; }
        }
      }
    }
    .boxes {
      .box {
        & { width: 100%; }
      }
    }
  }

  .dashboard-overview .overview-board .overview-header { border-radius: 0; justify-content: center; }

  .dashboard-overview .overview-board .boxes .box .active .location { top: -102px; left: -8px; }
  .dashboard-overview .overview-board .boxes .box .path-bar .on-shore { left: 18%; }
  .dashboard-overview .overview-board .boxes .box .path-bar .raising-sails { left: 67%; }
  .dashboard-overview .overview-board .boxes .box .path-bar .sailing-waves { left: 82%; }
  .dashboard-overview .overview-board .boxes .box .active p { font-size: 12px; }
  .dashboard-overview .overview-board .boxes .box .path-bar p { font-size: 10px; max-width: 8ch; }
  // .dashboard-overview .overview-board .boxes .box .path-img { margin-top: 4rem; }
  .dashboard-overview .overview-board .boxes .box .active .location-img { height: 25px; }

  .dashboard-overview .overview-board .boxes .box .chart-container { flex-direction: column; }

  .score-container { flex-direction: column; }
  .dashboard-overview .qr-container .invite-pl-container { flex-direction: column; }
  .dashboard-overview .qr-container .invite-pl-container .dotted-line { display: none; }
  .dashboard-overview .qr-container .invite-head { padding: 0 8px; }

}